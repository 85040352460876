import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

import { Row, Col, Popover, Button, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { IconFingerprint, IconShieldCheckFilled } from '@tabler/icons-react'

import { setShowAlertFeedback, setShowConfirmationModal } from '../../../../../store/internal';


import { getToken } from '../../../../../store/token'

import Loading from '../../../../../components/loading'

import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import { TokenInterfaceControlPanel } from '../../../../../services/requestsInterfacesModel';

import styles from '../styles';
import ModalEditarAccountController from '../../modalEditVozxpress/indexController';

const AppRequesterConst = new AppRequesterController();

const ListConfigVozxpress = (props) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    
    const [currentAgentData, setCurrentAgentData] = useState(null);

    const [showModalEdit, setShowModalEdit] = useState(false);
    const  [idAccount, setIdAccount] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    
    const funtionConfirmation = async (id: string, delete_vx) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        };

        await AppRequesterConst.Delete(
            `/panel/vozxpress/account/${id}?delete_in_vx=${delete_vx}`, { headers },
            (response: Object) => {},
            (data: any) => {
                if (delete_vx) {
                    dispatch(setShowAlertFeedback({ message: "Integração entre agente e atendente desfeita e atendente excluída com sucesso.", visibility: true, signalIcon: true}))
                } else {
                    dispatch(setShowAlertFeedback({ message: "Integração entre agente do CX e atendente do VX desfeita com sucesso", visibility: true, signalIcon: true}))
                }
                props.updateListAfterDelete();
                props.getAccountsVx();
            },
            (error: { response: { status: number } }) => {
                dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }))
            }, navigate, dispatch, setIsLoading,
        )
    }

    const deleteAccount = (data, delete_vx=true) => {
        let id = data?.vozxpress_account.id;
        let message = delete_vx ? 'Tem certeza que deseja remover integração e excluir o atendente no VX?' : 'Tem certeza que deseja remover integração?';
        let title = delete_vx ? 'Remover integração e excluir atendente' : 'Remover integração';

        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": title, "body": message, "buttonReturnText": "Cancelar", "buttonConfirmationText": "Confirmar" }, functionConfirmation: () => funtionConfirmation(id, delete_vx) }));
    }

    const handleOpenModalEdit = () => {
        setShowModalEdit(true);
    };

    const handleCloseModalEdit = () => {
        setShowModalEdit(false);
    }

    const values: TokenInterfaceControlPanel = {
        token: {
            value: useSelector(getToken)
        }
    }

    // const getAccountById = async (id) => {
    //     setIdAccount(id)
    //     const headers = {
    //         "Content-Type": "application/json; charset=utf-8",
    //         "Authorization": "Bearer " + values.token.value
    //     };

    //     await AppRequesterConst.Get(
    //         `/panel/vozxpress/account/${id}`, { headers },
    //         (response: Object) => {},
    //         (data: any) => {
    //             if(data?.status === 200) {
    //                 setCurrentAgentData(data?.data);
    //             }
    //         },
    //         (error: { response: { status: number } }) => {
    //             if (error.response.status === 400) {

    //             }
    //             if (error.response.status === 401) {

    //             }
    //         }, navigate, dispatch, setIsLoading, { }
    //     )
    // }

    const openMoreInfo = (data) => {
        let info = {
          account:  {
            email: data?.vozxpress_account?.email,
            extension: data?.vozxpress_account?.extension,
            type: 'Account'
          },
          user: !data?.is_vx_user ? undefined :
          {
            email: data?.vozxpress_user?.email,
            type: 'User'
          }
        }
        props.setMoreInfo(info);
        props.setShowMoreInfo(true);
    }

    const openIntegrateAccount = (data) => {
        props.setMoreInfo(data);
        props.setShowIntegrateAccount(true)
    }

    const modalIntegrateWithToken = (data) => {
        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": 'Integrar no CX', "body": 'Ao clicar em confirmar será criado ou atualizado o token de integração entre as plataformas, VX e CX', "buttonReturnText": "Cancelar", "buttonConfirmationText": "Confirmar" }, functionConfirmation: () => integrateWithToken(data) }));
    }

    const integrateWithToken = async (data) => {

        let body: Object;

        if (data?.vozxpress_account?._id !== undefined && data?.vozxpress_user?._id !== undefined) {
            body = {
                vx_user_id: data?.vozxpress_user?._id,
                vx_account_id: data?.vozxpress_account?._id
            }
        } else if (data?.vozxpress_account?._id !== undefined && data?.vozxpress_user?._id == undefined) {
            body = {
                vx_account_id: data?.vozxpress_account?._id
            }
        } else if (data?.vozxpress_account?._id == undefined && data?.vozxpress_user?._id !== undefined) {
            body = {
                vx_user_id: data?.vozxpress_user?._id
            }
        }

        await Promise.all([integrateWithTokenReq(body)])
        .then(_ => {
            dispatch(setShowAlertFeedback({ message: "Token de integração sem senha criado ou atualizado com sucesso.", visibility: true, signalIcon: true}));
        })
        .catch(err => {
            dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }));
        })

        // if (data.is_vx_user) {
        //     let body = {
        //         // type: 'user',
        //         vx_user_id: data?.vozxpress_user?._id
        //     }
        //     let body2 = {
        //         // type: 'account',
        //         vx_account_id: data?.vozxpress_account?._id
        //     }

            

        // } else {
        //     // let body = {
        //     //     type: data.is_vx_user ? 'user': 'account',
        //     //     vx_id: data.is_vx_user ? data?.vozxpress_user?._id : data?.vozxpress_account?._id
        //     // }

        //     let body = {
        //         // type: 'user',
        //         vx_user_id: data?.vozxpress_user?._id
        //     }
            
        //     let body2 = {
        //         // type: 'account',
        //         vx_account_id: data?.vozxpress_account?._id
        //     }
        
        //     await Promise.all([integrateWithTokenReq(body), integrateWithTokenReq(body2)])
        //     .then(_ => {
        //         dispatch(setShowAlertFeedback({ message: "Token de integração sem senha criado ou atualizado com sucesso.", visibility: true, signalIcon: true}));
        //     })
        //     .catch(err => {
        //         dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }));
        //     })

        // }
    }

    const integrateWithTokenReq = async (body) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        };
        await AppRequesterConst.Put(
            `/panel/vozxpress/update-token-integration`, body, { headers },
            (response: Object) => {},
            (data: any) => {
                props.getAgentsWithAccount();
            },
            (error: { response: { status: number } }) => {
            }, navigate, dispatch, setIsLoading,
        )
    }

    const modalHandleCreateAccount = (data) => {
        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": 'Criar atendente', "body": 'Ao clicar em confirmar será criado um atendente no VX (caso ainda nao exista um atendente com o e-mail do agente) e feita a integração', "buttonReturnText": "Cancelar", "buttonConfirmationText": "Confirmar" }, functionConfirmation: () => handleCreateAccount(data) }));
    }

    const handleCreateAccount = async (data) => {
        const JsonSend = {
            "user_id": data.user_id,
            "user_type": 'account',
            "token_integration": undefined
        };
        
        const headers = {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + values.token.value
        };
        
        AppRequesterConst.Post(
            '/panel/vozxpress/account',
            JsonSend,
            { headers: headers },
            (response: Object) => {
                return response;
            },
            (data: Object) => {
                dispatch(setShowAlertFeedback({ message: "Atendente criada e integrada com sucesso.", visibility: true, signalIcon: true }))
                props.updateListAfterDelete();
                props.getAccountsVx();
            },
            (error: { response: { status: number; data: { message: { message: string, path: []; }, code_cxpress: number; }; }; }) => {
                if (error.response !== undefined) {
                    if (error.response.status === 400) {
                        if (error.response.data.code_cxpress === 1812) {
                            dispatch(setShowAlertFeedback({ message: "Não foi possível atualizar o token de autenticação", visibility: true, signalIcon: false }));
                        } else if (error.response.data.code_cxpress === 1835) {
                            dispatch(setShowAlertFeedback({ message: "Empresa não possui integração ativa com o Vozxpress", visibility: true, signalIcon: false }));
                        }
                    }
                } else {
                    dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }))
                }
            }, navigate, dispatch, setIsLoading);
    }

    const popoverOptions = (data) => {
        const disableCreate = props.accountsVx.find(item => item.email === data.email);
        return (
            <Popover style={{ zIndex: '500', backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
                <Popover.Body className="nopadding">
                    {
                        !data?.vozxpress_account 
                        ? 
                        <>
                            <Button onClick={() => openIntegrateAccount(data)} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>Integrar com atendente (VX)</Button>
                            {
                                !disableCreate &&
                                <div>
                                    <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                                    <Button onClick={() => modalHandleCreateAccount(data)} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>Criar Atendente (VX)</Button>
                                </div>
                            } 
                        </>
                        : 
                        <>
                            <Button onClick={()=> modalIntegrateWithToken(data)} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>Atualizar Token</Button>
                            <div>
                                <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                                <Button onClick={()=> deleteAccount(data, false)} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>Remover Integração</Button>
                            </div>
                            <div>
                                <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                                <Button onClick={()=> deleteAccount(data)} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>Excluir Atendente</Button>
                            </div>
                            <div>
                                <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                                <Button onClick={()=> openMoreInfo(data)} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>Mais informações</Button>
                            </div>
                        </>
                    }
                </Popover.Body>
            </Popover>
        ) 
    }

    return (
        <>
            <Loading visibility={isLoading}></Loading>
            <div  className="ps-3">
                <ListGroup variant="flush">
                    <ListGroup.Item style={styles.textRowCollums}>
                        <Row name="ListOfAgentsHead">

                            <Col md={3} className="nopadding">
                                <p>Nome</p>
                            </Col>
                            <Col md={4} className="nopadding">
                                <p>E-mail</p>
                            </Col>
                            <Col md={3} className="nopadding">
                                <p>Conta no VX</p>
                            </Col>

                            <Col md={1} className='nopadding'>
                                { props.isEditAllowed && <p>Ações</p>}
                            </Col>
                        </Row>
                    </ListGroup.Item>
                </ListGroup>
                {
                    props.datas !== undefined ?
                        <div id="ListOfAgents" style={{ overflowY: 'auto', height: '56vh' }}>
                            {
                                <ListGroup variant="flush" className="TicketsRow">
                                {props.datas
                                    .slice()
                                    .sort((a, b) => {
                                        const nameA = a.name ? a.name.toLowerCase() : 'n/a';
                                        const nameB = b.name ? b.name.toLowerCase() : 'n/a';
                                        return nameA.localeCompare(nameB);
                                    })
                                    .map((item, index) => {
                                        return (
                                            <ListGroup.Item key={index} style={{ color: '#707070' }}>
                                                <Row className='TicketsRowHeight'>
                                                    <Col md={3} className='align-self-center nopadding' style={{ cursor: 'pointer'}}>
                                                        <div className="oneLineClampEllipsis me-3">
                                                            <OverlayTrigger
                                                                key='top'
                                                                placement='top'
                                                                overlay={
                                                                    <Tooltip id='tooltip'>
                                                                        {item?.name ?
                                                                            <>{item?.name}</>
                                                                            :
                                                                            <>N/A</>
                                                                        }
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span>
                                                                    {item.name? item.name: <>N/A</>}
                                                                </span>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </Col>
                                                    <Col md={4} className='align-self-center nopadding' style={{ cursor: 'pointer'}}>
                                                        <div className="oneLineClampEllipsis me-3">
                                                            <OverlayTrigger
                                                                key='top'
                                                                placement='top'
                                                                overlay={
                                                                    <Tooltip id='tooltip'>
                                                                        {item.email != null ?
                                                                            <>{item.email}</>
                                                                            :
                                                                            <>N/A</>
                                                                        }
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span>
                                                                    {item.email? item.email: <>N/A</>}
                                                                </span>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </Col>
                                                    <Col md={2} className='align-self-center nopadding' style={{ cursor: 'pointer'}}>
                                                        <div className="oneLineClampEllipsis me-3">
                                                            <OverlayTrigger
                                                                key='top'
                                                                placement='top'
                                                                overlay={
                                                                    <Tooltip id='tooltip'>
                                                                        {item.has_vx_account ?
                                                                            <>Sim</>
                                                                            :
                                                                            <>Não</>
                                                                        }
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span>
                                                                    {item.has_vx_account? <>Sim</> : <>Não</>}
                                                                </span>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                key='top'
                                                                placement='top'
                                                                overlay={
                                                                    <Tooltip id='tooltip'>
                                                                        {item.has_vx_account ?
                                                                            <>É o user no VX</>
                                                                            :
                                                                            <>Não</>
                                                                        }
                                                                    </Tooltip>
                                                                }
                                                            >
                                                               <span>
                                                                    {item.is_vx_user ? <> <IconShieldCheckFilled /></> : null}
                                                                </span> 
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                key='top'
                                                                placement='top'
                                                                overlay={
                                                                    <Tooltip id='tooltip'>
                                                                        {item.has_vx_account ?
                                                                            <>Possui integração sem senha</>
                                                                            :
                                                                            <>Não</>
                                                                        }
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span>
                                                                    {item.has_token_integration ? <> <IconFingerprint /></> : null}
                                                                </span>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </Col>
                                                    <Col md={1} className='d-flex justify-content-end' style={{marginLeft:'2.3rem'}} >
                                                        {
                                                            props.isEditAllowed &&
                                                            <OverlayTrigger rootClose={true} trigger={['click']} placement="left" overlay={popoverOptions(item)}>
                                                                <Button className='nopadding d-flex justify-content-start align-self-center' style={{ marginTop: '', backgroundColor: 'transparent', border: 'none', width: "24px" }}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="90%" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#6A6A6A" fill="#6A6A6A" strokeLinecap="round" strokeLinejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                        <circle cx="12" cy="12" r="1" />
                                                                        <circle cx="12" cy="19" r="1" />
                                                                        <circle cx="12" cy="5" r="1" />
                                                                    </svg>
                                                                </Button>
                                                            </OverlayTrigger>
                                                        }
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        );
                                    })}
                                </ListGroup>
                            }
                        </div>
                    :
                        <div className='align-self-center' style={{ overflow: 'hidden', height: '57vh', backgroundColor: '#F9F9F9' }}>
                            <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '17vh', backgroundColor: '#F9F9F9' }}>
                                <Col md={2} className="d-flex justify-content-center">
                                    <svg id="Grupo_11213" data-name="Grupo 11213" xmlns="http://www.w3.org/2000/svg" width="4vw" viewBox="0 0 24 24">
                                        <path id="Caminho_9567" data-name="Caminho 9567" d="M0,0H24V24H0Z" fill="none" />
                                        <circle id="Elipse_158" data-name="Elipse 158" cx="4" cy="4" r="4" transform="translate(5 3)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Caminho_9568" data-name="Caminho 9568" d="M3,21V19a4,4,0,0,1,4-4h4a4,4,0,0,1,4,4v2" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Caminho_9569" data-name="Caminho 9569" d="M16,11h6M19,8v6" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    </svg>
                                </Col>
                            </Row>
                            <Row className='d-flex justify-content-center align-content-center mt-2' style={{ marginLeft: '2%', backgroundColor: '#F9F9F9' }}>
                                <Col md={4}>
                                    <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>Sem resultados, adicione uma atendente</p>
                                </Col>
                            </Row>
                        </div>
                }
                {/* <ModalEditarAccountController
                show={showModalEdit}
                handleClose={handleCloseModalEdit}
                data={currentAgentData}
            /> */}
            </div>
        </>
    );

}

export default ListConfigVozxpress;