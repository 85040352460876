import { ChangeEvent, ClipboardEvent, FC, useEffect, useRef, useState } from 'react';
import CustomInput from '.';
import { CustomInputControllerInterface, TagifyEvent } from './indexModel';
import { stdnum } from 'stdnum';

import phoneLangPT from 'react-phone-input-2/lang/pt.json';
import phoneLangES from 'react-phone-input-2/lang/es.json';
import phoneLangFR from 'react-phone-input-2/lang/fr.json';

const CustomInputController: FC<CustomInputControllerInterface> = (props) => {
  
  const customInputRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [inputWidth, setInputWidth] = useState<number>(0);
  const [isValidInput, setIsValidInput] = useState(true);
  const didRun = useRef(false);
  // const isValidInputRef = useRef(true);

  const EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  useEffect(() => {
    if (!didRun.current) {
      const useValueInputs = ['input', 'textarea', 'email', 'phone', 'document'];
      if (props.type === 'tags') {
        validation(props.tagsValue);
        didRun.current = props.tagsValue?.length > 0;
      } else if (props.type === 'input-number') {
        validation(props.valueNumber);
        didRun.current = props.valueNumber.toString().length > 0;
      } else if (props.type === 'datepicker' || props.type === 'timepicker') {
        if (props.datePickerSettings.selectsRange) {
          const startDate = new Date(props.datePickerSettings.startDate);
          const endDate = new Date(props.datePickerSettings.endDate);

          didRun.current = !isNaN(startDate.getTime()) && !isNaN(endDate.getTime());
        } else {
          const date = new Date(props.datePickerSettings.startDate);
          didRun.current = !isNaN(date.getTime());
        }
        validation(props.value);
      } else if (useValueInputs.includes(props.type)) {
        validation(props.value);
        didRun.current = !!props.value;
      }
    }
  }, [
    props.tagsValue, 
    props.valueNumber, 
    props.value, 
    props.datePickerSettings?.startDate, 
    props.datePickerSettings?.endDate,
    props.datePickerSettings?.selectsRange
  ]);

  useEffect(() => {
    if (props.type === 'datepicker') {
      const updateWidth = () => {
        if (customInputRef.current) {
          setInputWidth(customInputRef.current.offsetWidth);
        }
      }
  
      updateWidth();
  
      const resizeObserver = new ResizeObserver(() => {
        updateWidth();
      });
  
      if (customInputRef.current) {
        resizeObserver.observe(customInputRef.current);
      }
  
      return () => {
        if (customInputRef.current) {
          resizeObserver.unobserve(customInputRef.current);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (props.type === 'datepicker' || props.type === 'timepicker' && Object.hasOwn(props.datePickerSettings, 'valid')) {
      setIsValidInput(props.datePickerSettings.valid);
    }
  }, [props.type, props.datePickerSettings?.valid]);

  useEffect(() => {
    if (props.inputFocus) {
      if (inputRef.current) {
        inputRef.current.focus();
      }
      if (textareaRef.current) {
        textareaRef.current.focus();
      }
    }
  }, [props.inputFocus]);

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (!props.disabled) {
      if (props.regexValueBlock) {
        if (!props.regexValueBlock.test(e.target?.value)) {
          if (Object.hasOwn(props, 'setValue')) {
            props.setValue(props.type === 'document' ? e.target?.value.toUpperCase() : e.target?.value);
          }
          if (Object.hasOwn(props, 'setValueNumber')) {
            props.setValueNumber(Number(e.target?.value));
          }
          if (Object.hasOwn(props, 'setCustomValue')) {
            props.setCustomValue(props.id, props.type === 'document' ? e.target?.value.toUpperCase() : e.target?.value);
          }
    
          validation(e.target?.value);
        }
      } else {
        if (Object.hasOwn(props, 'setValue')) {
          props.setValue(props.type === 'document' ? e.target?.value.toUpperCase() : e.target?.value);
        }
        if (Object.hasOwn(props, 'setValueNumber')) {
          props.setValueNumber(Number(e.target?.value));
        }
        if (Object.hasOwn(props, 'setCustomValue')) {
          props.setCustomValue(props.id, props.type === 'document' ? e.target?.value.toUpperCase() : e.target?.value);
        }
  
        validation(e.target?.value);
      }
    }
  }

  const handlePasteInput = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();

    const pastedText = e.clipboardData.getData('Text');
    const inputElement = inputRef.current;
    const startSelection = inputElement?.selectionStart ?? 0;
    const endSelection = inputElement?.selectionEnd ?? 0;
  
    // Processa o texto colado de acordo com o regexValueBlock, se definido
    const processedText = props.regexValueBlock
      ? pastedText.replace(new RegExp(props.regexValueBlock.source, 'g'), '')
      : pastedText;
  
    const finalText = props.type === 'document' ? processedText.toUpperCase() : processedText;
  
    // Valor atual do input
    const currentValue = inputElement?.value || '';
  
    // Calcula o novo valor do input
    const newValue = startSelection !== endSelection
      ? currentValue.slice(0, startSelection) + finalText + currentValue.slice(endSelection)
      : currentValue.slice(0, startSelection) + finalText + currentValue.slice(startSelection);
  
    // Atualiza com setValue, se disponível
    if (Object.hasOwn(props, 'setValue')) {
      props.setValue(newValue);
    }
  
    // Atualiza com setValueNumber, se disponível
    if (Object.hasOwn(props, 'setValueNumber')) {
      const numericValue = Number(newValue.replace(/[^\d]/g, '')); // Remove caracteres não numéricos
      props.setValueNumber(numericValue);
    }
  
    // Atualiza com setCustomValue, se disponível
    if (Object.hasOwn(props, 'setCustomValue')) {
      props.setCustomValue(props.id, newValue);
    }
  
    // Ajusta o cursor para o final do texto colado
    setTimeout(() => {
      inputElement?.setSelectionRange(startSelection + finalText.length, startSelection + finalText.length);
    }, 0);

    validation(newValue);
  };


  const handleChangeTextarea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (!props.disabled) {
      if (props.regexValueBlock) {
        let value = e.target?.value;

        if (e.type === 'paste') {
          value = value.replace(new RegExp(props.regexValueBlock.source, 'g'), '');
        }

        if (props.regexValueBlock.test(value)) {
          if (Object.hasOwn(props, 'setValue')) {
            props.setValue(value);
          }
          if (Object.hasOwn(props, 'setCustomValue')) {
            props.setCustomValue(props.id, value);
          }
          validation(value);
        }
      } else {
        if (Object.hasOwn(props, 'setValue')) {
          props.setValue(e.target.value);
        }
        if (Object.hasOwn(props, 'setCustomValue')) {
          props.setCustomValue(props.id, e.target.value);
        }
        validation(e.target?.value);
      }
    }
  }

  const handlePasteTextarea = (e: ClipboardEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
  
    const pastedText = e.clipboardData.getData('Text');
    const textareaElement =  textareaRef.current;
    const startSelection = textareaElement.selectionStart ?? 0;
    const endSelection = textareaElement.selectionEnd ?? 0;
  
    let finalText = pastedText;
  
    // Aplicar regexValueBlock, se definido
    if (props.regexValueBlock) {
      finalText = pastedText.replace(new RegExp(props.regexValueBlock.source, 'g'), '');
    }
  
    // Valor atual do textarea
    const currentValue = textareaElement.value;
  
    // Novo valor com base em seleção
    const newValue = startSelection !== endSelection
      ? currentValue.slice(0, startSelection) + finalText + currentValue.slice(endSelection)
      : currentValue.slice(0, startSelection) + finalText + currentValue.slice(startSelection);
  
    // Atualizar o valor através das props disponíveis
    if (Object.hasOwn(props, 'setValue')) {
      props.setValue(newValue);
    }
  
    if (Object.hasOwn(props, 'setCustomValue')) {
      props.setCustomValue(props.id, newValue);
    }
  
    // Ajustar a posição do cursor após a colagem
    setTimeout(() => {
      const cursorPosition = startSelection + finalText.length;
      textareaElement.setSelectionRange(cursorPosition, cursorPosition);
    }, 0);

    validation(newValue);
  };

  const handleChangeTags = (e: TagifyEvent) => {
    if (!props.disabled) {
      const newValue = e.detail.tagify.getCleanValue().map((tag) => {
        return tag.value
      });
      props.setTagsValue(newValue);
      validation(newValue);
    }
  }

  const handleChangeDatepicker = (dates: Date | [Date, Date]) => {
    props.datePickerSettings.onChange(dates);
    validation(dates);
  }

  const handleChangePhone = (value: string) => {
    if (!props.disabled) {
      if (Object.hasOwn(props, 'setValue')) {
        props.setValue(value);
      }
      if (Object.hasOwn(props, 'setCustomValue')) {
        props.setCustomValue(props.id, value);
      }
      validation(value);
    }
  }

  const getLocale = () => {
    const i18nLanguage = localStorage.getItem('i18nextLng');
    const format = { language: 'pt-BR', dateFormat: 'dd/MM/yyyy' };

    if (i18nLanguage) {
      if (i18nLanguage.toLowerCase() === 'pt-pt') {
        format.language = 'pt-PT';
      } else {
        format.language = i18nLanguage;

        if (i18nLanguage.toLowerCase() === 'en') {
          format.dateFormat = 'MM/dd/yyyy';
        }
      }
    }

    return format;
  }

  const onCalendarIconClick = () => {
    const datepicker = document.getElementById('custom-input-datepicker');
    if (datepicker) {
      datepicker.click();
    }
  }
  const onTimeIconClick = () => {
    const timepicker = document.getElementById('custom-input-timepicker');
    if (timepicker) {
      timepicker.click();
    }
  }

  const getPhoneLocalization = () => {
    const i18nLanguage = localStorage.getItem('i18nextLng') || 'pt-br';

    switch (i18nLanguage.toLowerCase()) {
      case 'pt-pt':
      case 'pt-br': {
        return phoneLangPT;
      };
      case 'es': {
        return phoneLangES;
      };
      case 'fr': {
        return phoneLangFR;
      }
      default: {
        return undefined;
      }
    }
  }

  const getPhoneDefaultCountry = () => {
    const i18nLanguage = localStorage.getItem('i18nextLng') || 'pt-br';

    switch (i18nLanguage.toLowerCase()) {
      case 'pt-br':
        return 'br';
      case 'pt-pt':
        return 'pt';
      case 'es':
        return 'es';
      case 'en':
        return 'gb';
      case 'fr':
        return 'fr';
      default:
        return 'br';
    }
  }

  const validation = (value: any): void => {
    const validation = {
      required: true,
      length: true,
      email:true,
      number: true,
      document: true,
      phone: true,
      datepicker: true,
    }

    if (!!props.required) {
      const useValueInputs = ['input', 'textarea', 'email', 'phone', 'document', 'tags'];

      if (useValueInputs.includes(props.type)) {
        validation.required = value?.length > 0;
      }

      if (props.type === 'input-number') {
        validation.required = !!(props.valueNumber?.toString().length > 0);
      }

      if (props.type === 'datepicker' || props.type === 'timepicker') {
        if (props.datePickerSettings.selectsRange) {
          const startDate = new Date(props.datePickerSettings.startDate);
          const endDate = new Date(props.datePickerSettings.endDate);

          validation.required = !isNaN(startDate.getTime()) && !isNaN(endDate.getTime());
        } else {
          const startDate = new Date(props.datePickerSettings.startDate);
          validation.required = !isNaN(startDate.getTime());
        }
      } else if (useValueInputs.includes(props.type)) {
        validation.required = value?.length > 0;
      }
    } else {
      validation.required = true;
    }

    if (props.type === 'input' || props.type === 'textarea') {
      if (props.inputLengthMin >= 0) {
        validation.length = value?.length >= props.inputLengthMin;
      }
      if (props.inputLengthMax >= 0) {
        validation.length = value?.length <= props.inputLengthMax;
      }
    }
    
    if (props.type === 'input-number') {
      validation.number = (Number(value) >= props.valueNumberMin) && (Number(value) <= props.valueNumberMax);
    } else {
      validation.number = true;
    }

    if (props.type === 'email') {
      if (!props.required && value.length === 0) {
        validation.email = true;
      } else {
        validation.email = EMAIL_PATTERN.test(value);
      }
    } else {
      validation.email = true;
    }

    if (props.type === 'document') {
      if (!props.required && value.length === 0) {
        validation.document = true;
      } else {
        validation.document = validateDocument(value);
      }
    } else {
      validation.document = true;
    }

    if (props.type === 'phone') {
      if (!props.required && value.length === 0) {
        validation.phone = true;
      } else {
        validation.phone = validatePhoneNumber(value, props.phoneInputSettings.countryDefault);
      }
    }

    if (props.type === 'datepicker' || props.type === 'timepicker') {
      if (Object.hasOwn(props.datePickerSettings, 'valid')) {
        validation.datepicker = props.datePickerSettings.valid;
      } 
    }

    const valid = Object.values(validation);

    if (props.isValidInputRef) {
      props.isValidInputRef.current = !valid.includes(false);
    }

    // isValidInputRef.current = !valid.includes(false);
    setIsValidInput(!valid.includes(false));
  }

  const validatePhoneNumber = (phoneNumber: string, country: string): boolean => {
    const phonePatterns: Record<string, RegExp> = {
      br: /^55[1-9][0-9](9[0-9]{8}|[2-8][0-9]{7})$/, // Ex: 5511987654321 ou 552132345678
      pt: /^351(2\d{8}|9\d{8})$/, // Ex: 351218765432 ou 351912345678
      fr: /^33[1-9]\d{8}$/, // Ex: 33712345678
      es: /^34[6-9]\d{8}$/, // Ex: 34612345678
      gb: /^44\d{10}|\d{11}$/, // Ex: 447712345678
    };

    const info = {
      br: { countryCode: '55', iso2: 'br' },
      55: { countryCode: '55', iso2: 'br' },

      pt: { countryCode: '351', iso2: 'pt' },
      351: { countryCode: '351', iso2: 'pt' },

      fr: { countryCode: '33', iso2: 'fr' },
      33: { countryCode: '33', iso2: 'fr' },

      es: { countryCode: '34', iso2: 'es' },
      34: { countryCode: '34', iso2: 'es' },

      gb: { countryCode: '44', iso2: 'gb' },
      44: { countryCode: '44', iso2: 'gb' },
    }

    let matchedCountry = country?.toLocaleLowerCase();

    if (!country) {
      if (phoneNumber.startsWith('55')) {
        matchedCountry = 'br';
      } else if (phoneNumber.startsWith('351')) {
        matchedCountry = 'pt';
      } else if (phoneNumber.startsWith('33')) {
        matchedCountry = 'fr';
      } else if (phoneNumber.startsWith('34')) {
        matchedCountry = 'es';
      } else if (phoneNumber.startsWith('44')) {
        matchedCountry = 'gb';
      }
    }

    const countryInfo = info[matchedCountry];

    const pattern = phonePatterns[countryInfo?.iso2];

    if (pattern) {
      const patternValid = pattern.test(phoneNumber);
      return patternValid || (!props.required && ((phoneNumber?.length === countryInfo?.countryCode?.length) || (phoneNumber?.length === 0)));
    } else {
      return phoneNumber?.length >= 8 && phoneNumber?.length <= 15;
    }
  }

  const validateDocument = (value: any) => {
    if (props.documentInputSettings && props.documentInputSettings.country) {
      const country = props.documentInputSettings.country.toLowerCase();
      const documentType = props.documentInputSettings.documentType;

      let valid = false;

      if (country === 'br') {
        if (documentType === 'personal') {
          valid = stdnum.BR.cpf.validate(value).isValid;
        } else {
          valid = stdnum.BR.cpf.validate(value).isValid || stdnum.BR.cnpj.validate(value).isValid;
        }
      } else if (country === 'es') {
        if (documentType === 'personal') {
          valid = stdnum.ES.nif.validate(value).isValid;
        } else {
          valid = stdnum.ES.nif.validate(value).isValid || stdnum.ES.cif.validate(value).isValid;
        }
      } else if (country === 'fr') {
        if (documentType === 'personal') {
          valid = stdnum.FR.nir.validate(value).isValid;
        } else {
          valid = stdnum.FR.nir.validate(value).isValid || stdnum.FR.siren.validate(value).isValid;
        }
      } else if (country === 'gb') {
        if (documentType === 'personal') {
          valid = stdnum.GB.nino.validate(value).isValid;
        } else {
          valid = valid = stdnum.GB.nino.validate(value).isValid || stdnum.GB.vat.validate(value).isValid;
        }
      } else if (country === 'pt') {
        if (documentType === 'personal') {
          valid = stdnum.PT.nif.validate(value).isValid;
        } else {
          valid = stdnum.PT.nif.validate(value).isValid || stdnum.PT.nipc.validate(value).isValid;
        }
      }
      
      return valid;
    }
  }

  const getDocumentMask = (): string => {
    if (props.documentInputSettings && props.documentInputSettings.country) {
      const country = props.documentInputSettings.country.toLowerCase();
      const documentType = props.documentInputSettings.documentType;
      const cleanedValue = props.value.replace(/[^a-zA-Z0-9]/g, '');

      let mask = '99999999999999';

      if (country === 'br') {
        if (documentType === 'personal') {
          mask = '999.999.999-99';
        } else {
          if (cleanedValue.length > 11 || documentType === 'company') {
            mask = '99.999.999/9999-99';
          } else {
            mask = '999.999.999-999'; // Padrão: 999.999.999-99 | O último 9 é pra garantir que pode ir pra outra máscara
          }
        }
      } else if (country === 'es') {
        mask = '*9999999a';
      } else if (country === 'fr') {
        if (documentType === 'personal') {
          mask = '9 99 99 99 999 999 99';
        } else {
          if (cleanedValue.length < 10 || documentType === 'company') {
            mask = '999 999 9999'; // Padrão: 999 999 999 | O último 9 é pra garantir que pode ir pra outra máscara
          } else {
            mask = '9 99 99 99 999 999 99';
          }
        }
      } else if (country === 'gb') {
        if (documentType === 'personal') {
          mask = 'aa 99 99 99 a';
        } else {
          if (!Number.isNaN(Number(cleanedValue[8])) || cleanedValue.length > 9 || documentType === 'company') {
            mask = 'aa 999 9999 99 999';
          } else {
            mask = 'aa 99 99 99 *';
          }
        }
      } else if (country === 'pt') {
        mask = '999999999';
      }

      return mask;
    }
  }

  return (
    <CustomInput 
      type={props.type}
      customInputRef={customInputRef}
      inputRef={inputRef}
      textareaRef={textareaRef}
      inputWidth={inputWidth}
      value={props.value}
      valueNumber={props.valueNumber}
      valueNumberMin={props.valueNumberMin}
      valueNumberMax={props.valueNumberMax}
      inputLengthMin={props.inputLengthMin}
      inputLengthMax={props.inputLengthMax}
      tagsValue={props.tagsValue}
      isValidInput={isValidInput}
      setValue={props.setValue}
      setValueNumber={props.setValueNumber}
      setCustomValue={props.setCustomValue}
      placeholder={props.placeholder}
      disabled={props.disabled}
      required={props.required}
      handleChangeInput={handleChangeInput}
      handlePasteInput={handlePasteInput}
      handleChangeTextarea={handleChangeTextarea}
      handlePasteTextarea={handlePasteTextarea}
      handleChangeTags={handleChangeTags}
      handleChangeDatepicker={handleChangeDatepicker}
      handleChangePhone={handleChangePhone}
      tagifySettings={props.tagifySettings}
      datePickerSettings={props.datePickerSettings}
      getLocale={getLocale}
      onCalendarIconClick={onCalendarIconClick}
      onTimeIconClick={onTimeIconClick}
      phoneInputSettings={props.phoneInputSettings}
      getPhoneLocalization={getPhoneLocalization}
      getPhoneDefaultCountry={getPhoneDefaultCountry}
      validatePhoneNumber={validatePhoneNumber}
      getDocumentMask={getDocumentMask}
    />
  );
}

export default CustomInputController;