import ViewTicket from ".";
import { FC, /*useContext,*/ useEffect, useRef, useState } from "react";
import { FetchTicketInterface, FetchTicketMessagesInterface, FetchUserConsumerInterface, SocketDataNewConsumerMessageInterface, TicketInterface, TicketMessageInterface, ViewTicketControllerInterface } from "./indexModel";
import { TicketStatus } from "../../../../core/enums/ticket-status";
import { useTranslation } from "react-i18next";
import { AppRequesterController } from "../../../../services/appRequester/appRequesterController";
import UserService from "../../../../services/user-service";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Channels } from "../../../../core/enums/channels";
import { useViewTicketContext } from "../../../../core/context/view-ticket-context";
import constsRouters from "../../../../routes/constsRouter";
import { IconBrandFacebook, IconBrandInstagram, IconBrandWhatsapp, IconMail, IconMessages, IconPhone } from "@tabler/icons-react";
import { useMainAppContext } from "../../../../core/context/main-app-context";
import { UserConsumerInterface } from "../../homeConsumers2/listOfConsumers/indexModel";
// import { SocketContext } from "../../../../core/context/socket-context";
import { EditorState } from "draft-js";
import { getIdUser, getNameUser, getProfileId } from "../../../../store/user";
import { setShowAlertFeedback } from "../../../../store/internal";
import { verifyCode } from "../../../../services/codeCxpressInterface";
import { CurrentAudioInfo } from "./components/chatEditorComponent/components/toolbarAudioRecorder/indexModel";
import { useSocketV2Context } from "../../../../core/context/socket-context-v2";
import emitter from "../../../../core/shared/emitter";
import { Popover } from "react-bootstrap";
import { PagesType } from "../../components-fixed/sidebarAppComponent/indexModel";
import Utils from '../../../../core/shared/utils';
import { CustomFile } from "./components/chatEditorComponent/components/toolbarFileUploader/indexModel";
import { T_StatusChatEditor, T_ToolbarItems } from "./components/chatEditorComponent/indexModel";
import { getEmployeeSectors } from "../../../../store/employee";
import { UserProfile } from "../../../../core/enums/user-profile";

const AppRequesterConst = new AppRequesterController();
const LIMIT_TICKET_MESSAGES = 15;
const MAX_HEIGHT_CHAT = 160; // Altura para definir quando abrir ou fechar o editor

const ViewTicketController: FC<ViewTicketControllerInterface> = (props) => {
  const { t } = useTranslation('ViewTicket');
  const { t: t_codeCxpress } = useTranslation('CodeCxpressErrors');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { emitSocketAppEvent, registerSocketAppEvent, unregisterSocketAppEvent, socketInitialized } = useSocketV2Context();
  // const socket = useContext(SocketContext);

  const { 
    loadingFetchData,
    isLoadingSelectedConsumer,
    currentTicket,
    currentConsumer,
    setTicketMessages,
    setCurrentTicket,
    setLoadingFetchData,
    setTicketIsBlocked,
    ticketIsBlocked,
    setTicketUsers,
    ticketUsers,
    setShowConsumerModal,
    isLoadingConsumerTickets,
    replyMessage,
    setIsLoadingSendMessage,
  } = useViewTicketContext();

  const {
    headerHeight,
    isSearched,
    actionEvent,
    actionEventData,
    emitActionEvent,
  } = useMainAppContext();

  const currentUserId = useSelector(getIdUser);
  const currentUserName = useSelector(getNameUser);

  const employee = {
    sectors: useSelector(getEmployeeSectors)
  };

  const user = {
    profile: useSelector(getProfileId)
  };
  
  // Data and requests states
  // const [apiHeader, setApiHeader] = useState(UserService.getHeaders());
  // const [loadingFetchData, setLoadingFetchData] = useState(false);

  // Ticket
  // const [ticket, setTicket] = useState<TicketInterface>(null);
  
  // Messages
  const newMessageIdRef = useRef<string>(null);
  const [ticketMessagesRequest, setTicketMessagesRequest] = useState<TicketMessageInterface[]>();
  // const [ticketMessages, setTicketMessages] = useState<TicketMessageInterface[]>();
  const [currentTicketMessagesPage, setCurrentTicketMessagesPage] = useState(1);
  const [hasMoreMessage, setHasMoreMessage] = useState(false);
  const [ticketIdUsers, setTicketIdUsers] = useState<string[]>([]);
  // const [currentTicketId, setCurrentTicketId] = useState<string>();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [files, setFiles] = useState<CustomFile[]>([]);
  const [hasFileUploader, setHasFileUploader] = useState(false);
  const [audioRecordedFiles, setAudioRecordedFiles] = useState<File[]>([]);
  const [currentAudioInfo, setCurrentAudioInfo] = useState<CurrentAudioInfo>();

  // Refs
  const ticketChatEditorContainerRef = useRef<HTMLDivElement>(null);
  const chatMessagesRef = useRef<HTMLDivElement>(null);
  const ticketChatContainerRef = useRef<HTMLDivElement>(null);
  const resizerRef = useRef<HTMLDivElement>(null);
  const hiddenToolbarRef = useRef<T_ToolbarItems[]>([]);

  // Scenes
  const [showChat, setShowChat] = useState(true);
  const [ticketChatEditorContainerHeight, setTicketChatEditorContainerHeight] = useState(150);
  const [chatMessageScrollPosition, setChatMessageScrollPosition] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [_isLoading, setIsLoading] = useState(false);
  const [showTicketDetails, setShowTicketDetails] = useState(true);
  const [showModalTicketLogs, setShowModalTicketLogs] = useState(false);
  const [showModalListTasks, setShowModalListTasks] = useState(false);
  const [isDeletedTicket, setIsDeletedTicket] = useState(false);
  const [showMenuAction, setShowMenuAction] = useState(false);
  const [showModalDeleteTicket, setShowModalDeleteTicket] = useState(false);
  const [showModalSendTicketCopy, setShowModalSendTicketCopy] = useState(false);
  const [showModalReopenTicket, setShowModalReopenTicket] = useState(false);
  const [showModalRecoveryDeletedTicket, setShowModalRecoveryDeletedTicket] = useState(false);

  // useEffect(() => {
  //   if (socket.getInstance()) {
  //     if (currentTicket && !currentTicket.who_is_viewing) {
  //       setCurrentTicketId(currentTicket.id);
  //       socket.emitEvent('start-ticket-chat', { ticket_id: currentTicket.id });
  //     }
  //   }
  //   console.log('mount ViewTicketController');
  //   return () => {
  //     console.log('unmount ViewTicketController');
  //     if (socket.getInstance()) {
  //       socket.emitEvent('leave-ticket-chat', currentTicketId);
  //       setCurrentTicketId(undefined);
  //     }
  //   };
  // }, [currentTicket, socket.getInstance()]);

  /*useEffect(() => {
    if (socket.getInstance()) {
      if (currentTicket && currentTicket.id !== currentTicketId) {
        if (!currentTicket.who_is_viewing) {
          // socket.emitEvent('start-ticket-chat', { ticket_id: currentTicket.id });
          // socket.emitEvent('leave-ticket-chat', currentTicketId);
          // setCurrentTicketId(currentTicket.id);
        } else {
          if (currentTicketId) {
            // socket.emitEvent('leave-ticket-chat', currentTicketId);
            // setCurrentTicketId(undefined);
          }
        }
      }
    }

    return () => {
      if (socket.getInstance()) {
        if (currentTicketId) {
          // socket.emitEvent('leave-ticket-chat', currentTicketId);
          // setCurrentTicketId(undefined);
        }
      }
    }
  }, [currentTicket, socket.getInstance()]);*/

  /*useEffect(() => {
    if (socket.getInstance()) {
      // Verificar evento quando é uma mensagem de observação interna
      socket.registerEvent('ticket-update', ticketUpdateMessage);
    }

    return () => {
      socket.unregisterEvent('ticket-update');
    }
  }, [socket.getInstance()]);*/

  useEffect(() => {
    if (currentTicket) {
      setFiles([]);
      setIsLoadingSendMessage(false);
      setIsDeletedTicket(!!currentTicket.deleted_at);
      const channelsWithAudio = [Channels.Email.toString(), Channels.WhatsApp.toString()];
      
      if (!channelsWithAudio.includes(currentTicket.channel_id)) {
        hiddenToolbarRef.current = ['audio-recorder'];
      }
    }
  }, [currentTicket]);

  useEffect(() => {
    setEditorState(EditorState.createEmpty());
    setFiles([]);
  }, []);

  useEffect(() => {
    if (ticketIsBlocked) {
      setTicketChatEditorContainerHeight(40);
    } else {
      setTicketChatEditorContainerHeight(150);
    }
  }, [currentTicket, ticketIsBlocked]);

  useEffect(() => {
    if (replyMessage) {
      setTicketChatEditorContainerHeight(230);
    } else {
      setTicketChatEditorContainerHeight(150);
    }
  }, [replyMessage]);

  useEffect(() => {
    if (props.selectedItemId && socketInitialized) {
      // setCurrentTicketId(props.selectedItemId);
      setCurrentTicketMessagesPage(1);
      setEditorState(EditorState.createEmpty());

      // Aqui só deve emitir o evento se não tiver ninguém olhando o ticket
      emitSocketAppEvent('start-ticket-chat', { ticket_id: props.selectedItemId });
      setLoadingFetchData(true);
      setTimeout(() => {
        fetchData();
      }, 500);
    }

    return () => {
      // setCurrentTicketId(null);
      if (socketInitialized) {
        emitSocketAppEvent('leave-ticket-chat', props.selectedItemId);
      }
    }
  }, [props.selectedItemId, socketInitialized]);

  useEffect(() => { // Salva a altura do componente de editor no início da viewTicket
    if (ticketChatEditorContainerRef?.current) {
      setTicketChatEditorContainerHeight(ticketChatEditorContainerRef.current.clientHeight);
    }
  }, []);

  // useEffect(() => {
  //   const userHeader = UserService.getHeaders();
  //   if (!apiHeader && userHeader) {
  //     setApiHeader(userHeader);
  //   }
  // }, [UserService.getHeaders()]);

  useEffect(() => { // Remove as datas repetidas quando forem carregadas novas mensagens
    const dates = [];
    const formattedMessages = [];
    const id_users: string[] = [];
    ticketMessagesRequest?.forEach(message => {
      if (message.user_id && !id_users.includes(message.user_id)) {
        id_users.push(message.user_id);
      }
      if (message.date) {
        if (!dates.includes(new Date(message.date).toLocaleDateString())) {
          dates.push(new Date(message.date).toLocaleDateString());
          formattedMessages.push(message);
        }
      } else {
        formattedMessages.push(message);
      }
    });
    // setTicketMessages(formattedMessages);
    setTicketMessages(formattedMessages);

    if (!Utils.compareStringArrays(ticketIdUsers, id_users)) {
      setTicketIdUsers(id_users);
    }
    
  }, [ticketMessagesRequest]);

  useEffect(() => {
    if (ticketIdUsers.length > 0) {
      const getTicketUsers = async () => {
        const usersObj: { [key: string]: string } = {};
        for await (const user_id of ticketIdUsers) {
          if (!ticketUsers[user_id]) {
            await fetchUser(user_id).then(user => {
              usersObj[user.user_id] = user.name;
            }).catch(error => {
              console.log(error);
            });
          }
        }
        setTicketUsers(usersObj);
      }
  
      getTicketUsers();
    } else {
      setTicketUsers({});
    }
  }, [ticketIdUsers]);

  useEffect(() => {
    if (files?.length > 0) {
      setHasFileUploader(true);
    } else {
      setHasFileUploader(false);
    }
  }, [files]);

  useEffect(() => {
    if (actionEvent === 'update-ticket-request' && actionEventData) {
      // fetchTicket(currentTicket.id);
      setCurrentTicket(prevState => {
        return { ...prevState, ...actionEventData.ticket };
      });
    }
    if (actionEvent === 'update-ticket-messages') {
      fetchTicketMessages(props.selectedItemId, false, 1, true);
    }
  }, [actionEvent]);

  useEffect(() => {
    emitter.on('socket-event::view-ticket:ticket-update', ticket => {
      if (props.selectedItemId && props.selectedItemId === ticket?.id) {
        socketEventTicketUpdate(ticket);
      }
    });

    emitter.on('socket-event::view-ticket:ticket-messages-update', data => {
      if (props.selectedItemId && props.selectedItemId === data.ticket_id) {
        setIsLoadingSendMessage(false);
        fetchTicketMessages(props.selectedItemId, false, 1, true);
      }
    });

    emitter.on('close-item-action-popover-view-ticket', () => {
      setShowMenuAction(false);
    });

    return () => {
      emitter.off('socket-event::view-ticket:ticket-update');
      emitter.off('socket-event::view-ticket:ticket-messages-update');
      emitter.off('close-item-action-popover-view-ticket');
    }
  }, [props.selectedItemId]);

  useEffect(() => {
    function viewTicketNewConsumerMessage(data: SocketDataNewConsumerMessageInterface) {
      emitter.emit('socket-event::view-ticket:ticket-messages-update', { ticket_id: data?.id });
    }

    function viewTicketNewAgentMessage(data: { message: TicketMessageInterface}) {
      emitter.emit('socket-event::view-ticket:ticket-messages-update', { ticket_id: data.message.ticket_id });
    }

    function viewTicketUpdate(data: { ticket: TicketInterface }) {
      emitter.emit('socket-event::view-ticket:ticket-update', data.ticket);
    }

    function viewTicketOnCreateMessageError(socketData: { response: { data: { code_cxpress: number, message: string } }}) {
      if (socketData?.response?.data?.code_cxpress) {
        if (socketData.response.data.code_cxpress === 5801) {
          dispatch(setShowAlertFeedback({ message: t('alert.error_create_message'), visibility: true, signalIcon: false }));
        } else {
          dispatch(setShowAlertFeedback({ message: verifyCode(socketData?.response?.data?.code_cxpress, t_codeCxpress), visibility: true, signalIcon: false }));
        }
      } else {
        dispatch(setShowAlertFeedback({ message: t('alert.error_create_message'), visibility: true, signalIcon: false }));
      }

      setTicketMessages(prevMessages => {
        prevMessages.forEach(item => {
          if (item._id === newMessageIdRef.current) {
            item.status = 'not sent';
          }
        });
        return [...prevMessages ];
      });
      
      setIsLoadingSendMessage(false);
    }

    function viewTicketSectorChanged(data: { ticket: TicketInterface }) {
      if (props.selectedItemId === data.ticket.id) {
        const employeeSectorsIds = employee.sectors.map(item => item.sector_id);

        if (data.ticket.sector && user.profile === UserProfile.Employee && !employeeSectorsIds.includes(data.ticket.sector.id)) {
          backToList();
        } else {
          emitter.emit('socket-event::view-ticket:ticket-update', data.ticket);
        }
      }
    }

    if (socketInitialized) {
      registerSocketAppEvent('new-consumer-message', viewTicketNewConsumerMessage);
      registerSocketAppEvent('new-agent-message', viewTicketNewAgentMessage);
      registerSocketAppEvent('ticket-update', viewTicketUpdate);
      registerSocketAppEvent('error-on-create-message', viewTicketOnCreateMessageError);
      registerSocketAppEvent('ticket-sector-changed', viewTicketSectorChanged);
    }

    return () => {
      if (socketInitialized) {
        unregisterSocketAppEvent('new-consumer-message', viewTicketNewConsumerMessage);
        unregisterSocketAppEvent('new-agent-message', viewTicketNewAgentMessage);
        unregisterSocketAppEvent('ticket-update', viewTicketUpdate);
        unregisterSocketAppEvent('error-on-create-message', viewTicketUpdate);
        unregisterSocketAppEvent('ticket-sector-changed', viewTicketSectorChanged);
      }
    }
  }, [socketInitialized]);

  useEffect(() => {
    if (currentTicket) {
      setTicketIsBlocked(isBlockedTicket(currentTicket));
    }
  }, [currentTicket?.who_is_viewing_user_id]);

  const socketEventTicketUpdate = (ticket: TicketInterface) => {
    setCurrentTicket(prevState => {
      return { ...prevState, ...ticket };
    });
    setTicketIsBlocked(isBlockedTicket(ticket));
  }

  const fetchData = async (socketUpdate?: boolean) => {
    if (!socketUpdate) {
      setLoadingFetchData(true);
    }
    
    await Promise.all([
      fetchTicket(props.selectedItemId),
      fetchTicketMessages(props.selectedItemId, false, 1),
    ]).then(() => {
      setLoadingFetchData(false);
    }).catch(() => {
      setLoadingFetchData(false);
    });
  }

  // const ticketUpdateMessage = (data: { ticket: TicketInterface }) => {
  //   if (data.ticket.id === currentTicket?.id) {
  //     fetchData(true);
  //   }
  // }

  const showHideChat = (value: boolean, keepCurrentHeight?: boolean) => {
    setShowChat(value);
    if (!keepCurrentHeight) {
      setTicketChatEditorContainerHeight(value ? 150 : 40);
    }
  }

  const getStatusIcon = (status_id: string): { value: string, color: string } => {
    switch (status_id) {
      case TicketStatus.Open: 
        return { value: t("ticket_status.open"), color: '#4A69BD' };
      case TicketStatus.Resolved: 
        return { value: t("ticket_status.resolved"),  color: '#707070' };
      case TicketStatus.Pending: 
        return { value: t("ticket_status.pending"), color: '#DE2450' };
      case TicketStatus.OnHold: 
        return { value: t("ticket_status.on_hold"), color: '#FBBD4E' };
      case TicketStatus.New: 
        return { value: t("ticket_status.new"), color: '#17a589' };
      case TicketStatus.Abandoned: 
        return { value: t("ticket_status.abandoned"), color: '#2D3233' };
      case TicketStatus.OverdueTicketTask: 
        return { value: t("ticket_status.delayed"), color: '#FFA500' };
      default:
        return { value: 'N/A', color: '#707070' };
    }
  }

  const getChannelIcon = (channel_id: string): { title: string, icon?: any } => {
    const size = 26;
    const color = '#707070';
    switch (channel_id) {
      case '3f3fa530-fd3e-4018-ab98-4d9c3cd5406a': // E-mail
        return { title: t("channels_names.email"), icon: <IconMail stroke={1.5} size={size} color={color} /> };
      case '6977996d-11d1-44cc-a863-2e1d9c24e046': // Telefonia
        return { title: t("channels_names.telephony"), icon: <IconPhone stroke={1.5} size={size} color={color} />};
      case '33be81d8-88ac-4e08-bb4d-cf69fd23267f': // Whatsapp
        return { title: t("channels_names.whatsapp"), icon: <IconBrandWhatsapp stroke={1.5} size={size} color={color} />};
      case 'aa1cea93-23de-46aa-af14-d6f766acf5c8': // Facebook
        return { title: t("channels_names.facebook"), icon: <IconBrandFacebook stroke={1.5} size={size} color={color} />};
      case 'f28cb4a4-e42b-425a-8011-59d5f9c1d0f2': // Instagram
        return { title: t("channels_names.instagram"), icon: <IconBrandInstagram stroke={1.5} size={size} color={color} />};
      case '4a7b71bb-6432-4794-ad11-f978b067871d': // Webchat
        return { title: t("channels_names.webchat"), icon: <IconMessages stroke={1.5} size={size} color={color} />};
      default:
        return { title: 'N/A' }
    }
  }

  // Funções da barra divisora redimensionável
  const handleDividerMouseDown = () => {
    document.addEventListener('mousemove', handleDividerMouseMove);
    document.addEventListener('mouseup', handleDividerMouseUp);
  };

  const handleDividerMouseMove = (event: any) => {
    setIsDragging(true);
    if (ticketChatEditorContainerRef?.current) {
      const newHeight = window.innerHeight - event.clientY;
      if (newHeight >= 50 && newHeight <= 600) {
        if (newHeight <= MAX_HEIGHT_CHAT) {
          showHideChat(false);
        } else {
          showHideChat(true, true);
        }
        setTicketChatEditorContainerHeight(newHeight - 14);
      }
    }
  };

  const handleDividerMouseUp = (event: any) => {
    setIsDragging(false);
    if (ticketChatEditorContainerRef?.current) {
      const newHeight = window.innerHeight - event.clientY;
      if (newHeight <= MAX_HEIGHT_CHAT) {
        showHideChat(false);
      } else {
        showHideChat(true, true);
      }
    }
    document.removeEventListener('mousemove', handleDividerMouseMove);
    document.removeEventListener('mouseup', handleDividerMouseUp);
  };

  const handleDividerDoubleClick = () => {
    showHideChat(!showChat);
  }

  const getConsumerInfo = () => {
    const consumerInfo = { value: '', isDeleted: false };

    if (currentTicket?.requester_user?.name) {
      consumerInfo.value += `${currentTicket.requester_user.name}`;
    }
    if (currentTicket?.channel_id === Channels.WhatsApp && currentTicket?.requester_user?.whatsapp) {
      consumerInfo.value += ` (${currentTicket.requester_user.whatsapp})`;
    }

    if (currentTicket?.requester_user) {
      consumerInfo.isDeleted = !!currentTicket.requester_user.deleted_at;
    } else {
      consumerInfo.isDeleted = true;
    }

    if (!consumerInfo.value) {
      consumerInfo.value = 'N/A';
    }

    return consumerInfo;
  }

  const fetchTicket = async (id: string) => {
    const headers = UserService.getHeaders();
    const controller = new AbortController();

    const options = Object.keys(controller.signal).length !== 0 ? { headers, signal: controller.signal } : { headers };
    
    await AppRequesterConst.Get(`/ticket/${id}`, options,
      (response: Object) => {},
      (response: FetchTicketInterface) => {
        setCurrentTicket(response.data.tickets[0]);

        setTicketIsBlocked(isBlockedTicket(response.data.tickets[0]));
      },
      (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {
        if (error?.response?.status === 401) {
          if (error?.response?.data?.code_cxpress === 1011) {
            return;
          }
          // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
        } else if (error?.response?.data) {
          // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          if (error.response.data.code_cxpress === 718) {
            dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          }
        }
        backToList();
      }, navigate, dispatch, setIsLoading, { }
    );
  }

  const fetchTicketMessages = async (id: string, isInfiniteScroll?: boolean, newPage?: number, onlyNewMessage?: boolean) => {
    const headers = UserService.getHeaders();
    const controller = new AbortController();
    const params = { limit: LIMIT_TICKET_MESSAGES, page: newPage || currentTicketMessagesPage }

    const options = Object.keys(controller.signal).length !== 0 ? { headers, signal: controller.signal, params } : { headers, params };

    await AppRequesterConst.Get(`/chat/${id}/messages`, options,
      (response: Object) => {},
      (response: FetchTicketMessagesInterface) => {
        const messages_count = response.data?.chat_messages?.filter(item => !item.date)?.length || 0;
        // console.log(response.data?.chat_messages?.length, LIMIT_TICKET_MESSAGES, messages_count)

        if (messages_count === LIMIT_TICKET_MESSAGES) {
          setHasMoreMessage(true);
        } else {
          setHasMoreMessage(false);
        }

        if (isInfiniteScroll) {
          if (response.data?.chat_messages) {
            setTicketMessagesRequest(prevData => {
              if (onlyNewMessage) {
                const messagesIdsObj: {[key: string]: string} = {};

                prevData.forEach(item => {
                  if (!item._id && item.date) {
                    messagesIdsObj[item.date] = item.date;
                  } else {
                    messagesIdsObj[item._id] = item._id;
                  }
                });

                const newMessages = response.data.chat_messages.filter(item => {
                  if (!item._id && item.date && !messagesIdsObj[item.date]) {
                    return item;
                  } else if (item._id && !messagesIdsObj[item._id]) {
                    return item;
                  }
                });

                return [...newMessages, ...prevData];
              } else {
                return [...response.data.chat_messages, ...prevData];
              }
            });
          }
        } else {
          if (response.data) {
            setTicketMessagesRequest(response.data?.chat_messages);
          } else {
            setTicketMessagesRequest([]);
          }
          scrollBottom();
        }
      },
      (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {
          if (error?.message === "canceled") {
              // requisição abortada
              return;
          } else {
              if (error?.response?.status === 401) {
                  if (error?.response?.data?.code_cxpress === 1011) {
                      return;
                  }
                  // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
              } else if (error?.response?.data) {
                  // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
              }
          }
      }, navigate, dispatch, setIsLoading, { }
    );
  }

  const fetchTicketMessagesPagination = async () => {
    chatMessagesRef.current.scrollTo({ top:  chatMessagesRef.current.scrollTop + 50 });
    if (hasMoreMessage) {
      const newPage = currentTicketMessagesPage + 1;
      setCurrentTicketMessagesPage(newPage);

      await fetchTicketMessages(props.selectedItemId, true, newPage);
    }
  }

  const fetchUser = async (id: string): Promise<UserConsumerInterface> => {
    const headers = UserService.getHeaders();
    const config = { headers };

    return new Promise(async (resolve, reject) => {
      await AppRequesterConst.Get(`/user/${id}`, config,
        (response: Object) => {},
        (response: FetchUserConsumerInterface) => {
          if (response.status === 200 && response.data?.users.length > 0) {
            resolve(response.data.users[0]);
          }
        },
        (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {
          if (error?.message === "canceled") {
            reject(error);
          } else {
            if (error?.response?.status === 401) {
              if (error?.response?.data?.code_cxpress === 1011) {
                // return;
                // reject(error);
              }
              // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
            } else if (error?.response?.data) {
              // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
            }
            reject(error);
          }
        }, navigate, dispatch, setIsLoading, { }
      );
    });
  }

  const scrollBottom = () => {
    if (chatMessagesRef.current) {
      // chatMessagesRef.current.scrollIntoView({ block: 'end' });
      chatMessagesRef.current.scrollTo({ top: 0 });
    }
  }

  const backToList = () => {
    setCurrentTicket(null);
    navigate(constsRouters.routers.tickets.path);
  }

  const getLoadings = (): boolean => {
    return isLoadingSelectedConsumer || loadingFetchData || isLoadingConsumerTickets;
  }

  const getHeaderHeight = () => {
    const ticketChatHeader = 40;
    const bottomDiff = 12;
    const pagesFilterBar: PagesType[] = [
      'consumer-tickets', 
      'agent-tickets', 
      'task-ticket', 
      'messages-trigger-tickets',
      'sector-tickets',
      'tag-tickets',
    ];
    const filterBarHeight = isSearched || pagesFilterBar.includes(props.pageType) ? 70 : 0;
    return headerHeight + ticketChatHeader + bottomDiff + filterBarHeight;
  }

  const openConsumerModal = () => {
    let isConsumerDeleted = false;

    if (currentTicket?.requester_user) {
      isConsumerDeleted = !!currentTicket.requester_user.deleted_at;
    } else {
      isConsumerDeleted = true;
    }

    if (!isConsumerDeleted && currentTicket?.channel_id !== Channels.Webchat) {
      setShowConsumerModal(true);
      setTimeout(() => {
        emitter.emit('open-modal-consumer', { userId: currentConsumer.user_id });
      }, 50);
    }
  }

  const isBlockedTicket = (ticket: TicketInterface, verifyStatus?: boolean): boolean => {
    if (ticket) {
      if (ticket.deleted_at) {
        return true;
      } else {
        const blockedTicketStatus = [TicketStatus.Abandoned.toString(), TicketStatus.Resolved.toString()];
        if (verifyStatus) {
          return blockedTicketStatus.includes(ticket.status_id);
        } else {
          const isCurrentUser = currentUserId === ticket.who_is_viewing_user_id;
          return (!!ticket.who_is_viewing && !isCurrentUser) || blockedTicketStatus.includes(ticket.status_id);
        }
      }
    } else {
      return false;
    }
  }

  const statusChatEditor = (): T_StatusChatEditor => {
    const data: T_StatusChatEditor = { blocked: false, blocked_type: null };

    if (currentTicket?.channel_id === Channels.WhatsApp) {
      if (!currentTicket?.consumer_last_message) { // Sem interação do consumidor
        data.blocked = true;
        data.blocked_type = 'not_consumer_message';
      } else { // Verificação se passou 24h sem interação do consumidor
        const currentDate = new Date();
        const lastMessageConsumerDate = new Date(currentTicket.consumer_last_message);
        const diff = (currentDate.getTime() - lastMessageConsumerDate.getTime()) / (1000 * 60 * 60);

        if (diff > 24) {
          data.blocked = diff > 24;
          data.blocked_type = 'last_consumer_message_24h';
        }
      }

      let onlyAudio = false;

      if (!data.blocked && files.length > 0) {
        onlyAudio = true;
        files.forEach(file => {
          if (!file.type.includes('audio')) {
            onlyAudio = false;
          }
        });

        if (onlyAudio) {
          data.blocked = true;
          data.blocked_type = 'whatsapp_only_audio';
          hiddenToolbarRef.current = ['bold', 'italic', 'underline', 'text-color', 'background-color', 'list-ordered', 'list-unordered', 'link', 'emoji'];
        } else {
          hiddenToolbarRef.current = [];
        }
      }
    }

    return data;
  }

  const closeTicketLogsModal = () => {
    setShowModalTicketLogs(false);
  }

  const sendMessage = (data: { content: string, files: string[], is_internal: boolean, id_quick_message?: string, id_template_message?: string, template_message_attachment?: string[] }) => {
    if (data.content || data.files?.length > 0) {
      const socketData = {
        cc: [],
        cco: [],
        channel_id: currentTicket.channel_id,
        content: data.content || '<span></span>',
        is_agent: true,
        is_internal_response: data.is_internal,
        ticket_id: currentTicket.id,
        user_id: currentUserId,
      }
  
      if (data.files?.length > 0) {
        socketData['temp_bucket_ids'] = data.files;
      }

      if (data.id_quick_message) {
        socketData['id_quick_message'] = data.id_quick_message;
      }

      if (data.id_template_message) {
        socketData['id_template'] = data.id_template_message;
      }

      newMessageIdRef.current =  Utils.generateObjectId();

      const mockMessage: TicketMessageInterface = {
        ...socketData,
        status: 'sending',
        _id: newMessageIdRef.current,
        createdAt: new Date().toISOString(),
        attachments: [],
        user_name: currentUserName,
        isMockedMessage: true
      }

      if (data.files?.length > 0) {
        mockMessage['attachments'] = data.files;
      } else if (data.template_message_attachment?.length > 0) {
        mockMessage['attachments'] = data.template_message_attachment;
      }

      // Verificar com o back como deve ser enviada as informações da resposta
      // e acrescentar no objeto socketData. Adicionar a mesma informação na mensagem mockada (mockMessage)
      // Falta fazer o front de como vai ficar essa mensagem com menção
      if (replyMessage) {

      }
      
      setTicketMessages(prevMessages => {
        return [...prevMessages, mockMessage];
      });

      scrollBottom();

      setIsLoadingSendMessage(true);
      emitSocketAppEvent('create-message', socketData);
  
      // socket.emitEvent('create-message', socketData);
    }
  }

  const openModalListTasks = () => {
    if (currentTicket) {
      setShowModalListTasks(true);
      setTimeout(() => {
        emitter.emit('open-modal-list-tasks', {
          ticketId: currentTicket.id, ticketReferenceId: currentTicket.ticket_reference_id
        });
      }, 100);
    }
  }

  const closeModalListTasks = () => {
    setShowModalListTasks(false);
  }

  const openModalDeleteTicket = (data: { ticketId: string, ticketReferenceId: string }) => {
    setShowModalDeleteTicket(true);
    emitter.emit('open-modal-delete-ticket', data);
  }

  const closeModalDeleteTicket = (reload: boolean, ticket_id: string) => {
    if (reload) {
      backToList();
      props.reloadTickets();
      if (ticket_id) {
        props.removeSelectedItem(ticket_id);
      }
    }
    setShowModalDeleteTicket(false);
  }

  const openModalSendTicketCopy = (data: { ticketId: string, ticketReferenceId: string, subject: string }) => {
    setShowModalSendTicketCopy(true);
    emitter.emit('open-modal-send-ticket-copy', data);
  }

  const closeModalSendTicketCopy = () => {
    setShowModalSendTicketCopy(false);
  }

  const openModalReopenTicket = (data: { ticketId: string, ticketReferenceId: string }) => {
    setShowModalReopenTicket(true);
    emitter.emit('open-modal-reopen-ticket', data);
  }

  const closeModalReopenTicket = (reload: boolean, ticket_id: string) => {
    if (reload) {
      props.reloadTickets();
      navigate(`${constsRouters.routers.tickets.path}/${ticket_id}`);
      fetchData();
    }
    setShowModalReopenTicket(false);
  }

  const openModalRecoveryDeletedTicket = (data: { ticketId: string, ticketReferenceId: string }) => {
    setShowModalRecoveryDeletedTicket(true);
    emitter.emit('open-modal-recovery-deleted-ticket', data);
  }

  const closeModalRecoveryDeletedTicket = (reload: boolean, ticket_id: string) => {
    if (reload) {
      props.reloadTickets();
      navigate(`${constsRouters.routers.tickets.path}/${ticket_id}`);
      fetchData();
    }
    setShowModalRecoveryDeletedTicket(false);
  }

  const handleShowTicketMenuAction = (value?: boolean) => {
    const popoverElement = document.querySelector('.popover-custom-action-menu') as HTMLDivElement;

    const newValue = value !== undefined ? value : !showMenuAction;

    if (popoverElement) {
      if (!popoverElement.classList.contains('show')) {
        popoverElement.classList.add('show');
        popoverElement.style.visibility = '';
        setShowMenuAction(true);
      } else {
        setShowMenuAction(newValue);
      }
    } else {
      setShowMenuAction(newValue);
    }
  }

  const handlePopoverTicketsListItemAction = (event: any, data: TicketInterface, type: 'reopen-email-ticket' | 'delete-ticket' | 'recovery-deleted-ticket' | 'reopen-ticket' | 'send-ticket-copy') => {
    event.stopPropagation();
    setShowMenuAction(false);
    setTimeout(() => {
      if (type === 'delete-ticket') {
        openModalDeleteTicket({ ticketId: data.id, ticketReferenceId: data.ticket_reference_id });
      } else if (type === 'recovery-deleted-ticket') {
        openModalRecoveryDeletedTicket({ ticketId: data.id, ticketReferenceId: data.ticket_reference_id });
      } else if (type === 'reopen-email-ticket') {
        openModalReopenTicket({ ticketId: data.id, ticketReferenceId: data.ticket_reference_id });
      } else if (type === 'send-ticket-copy') {
        openModalSendTicketCopy({ ticketId: data.id, ticketReferenceId: data.ticket_reference_id, subject: data.subject });
      }
    }, 50);
  }

  const popoverItemAction = () => {
    const isDeletedFilter = sessionStorage.getItem('is_deleted_counter');
    
    const showReopenEmailTicket = (isDeletedFilter === 'false' || !isDeletedFilter) && props.pageType === 'history-general' &&  currentTicket?.channel_id === Channels.Email;
    const showDeleteTicket = props.pageType === 'ticket-list' && (!currentTicket?.who_is_viewing || currentTicket?.who_is_viewing_user_id === currentUserId);
    const showRecoveryDeletedTicket = isDeletedFilter === 'true' && props.pageType === 'history-general' && currentTicket?.channel_id === Channels.Email;
    const showSendTicketCopy = true;

    return (
      <Popover bsPrefix='popover-custom-action-menu popover-custom-action-menu-view-ticket'>
        <Popover.Body>
          { showReopenEmailTicket &&
            <span className='popover-item' onClick={(e) => handlePopoverTicketsListItemAction(e, currentTicket, 'reopen-email-ticket')}>
              {t('list_popover_actions.reopen_email_ticket')}
            </span>
          }
          { showDeleteTicket &&
            <span className='popover-item' onClick={(e) => handlePopoverTicketsListItemAction(e, currentTicket, 'delete-ticket')}>
              {t('list_popover_actions.delete_ticket')}
            </span>
          }
          { showRecoveryDeletedTicket &&
            <span className='popover-item' onClick={(e) => handlePopoverTicketsListItemAction(e, currentTicket, 'recovery-deleted-ticket')}>
              {t('list_popover_actions.recovery_deleted_email_ticket')}
            </span>
          }
          { showSendTicketCopy &&
            <span className='popover-item' onClick={(e) => handlePopoverTicketsListItemAction(e, currentTicket, 'send-ticket-copy')}>
              {t('list_popover_actions.copy_ticket')}
            </span>
          }
        </Popover.Body>
      </Popover>
    );
  }
  
  return (
    <ViewTicket 
      t={t}
      pageType={props.pageType}
      getLoadings={getLoadings}
      currentLoggedUserID={currentUserId}
      ticket={currentTicket}
      hasMoreMessage={hasMoreMessage}
      fetchTicketMessagesPagination={fetchTicketMessagesPagination}
      onlyRead={ticketIsBlocked}
      isDeletedTicket={isDeletedTicket}
      util_getStatusIcon={getStatusIcon}
      util_getChannel={getChannelIcon}
      showChat={showChat}
      showHideChat={showHideChat}
      ticketChatEditorContainerRef={ticketChatEditorContainerRef}
      ticketChatEditorContainerHeight={ticketChatEditorContainerHeight}
      setTicketChatEditorContainerHeight={setTicketChatEditorContainerHeight}
      chatMessagesRef={chatMessagesRef}
      chatMessageScrollPosition={chatMessageScrollPosition}
      handleDividerMouseDown={handleDividerMouseDown}
      isDragging={isDragging}
      handleDividerDoubleClick={handleDividerDoubleClick}
      getConsumerInfo={getConsumerInfo}
      showTicketDetails={showTicketDetails}
      setShowTicketDetails={setShowTicketDetails}
      backToList={backToList}
      showList={props.showList}
      ticketChatContainerRef={ticketChatContainerRef}
      resizerRef={resizerRef}
      getHeaderHeight={getHeaderHeight}
      openConsumerModal={openConsumerModal}
      isBlockedTicket={isBlockedTicket}
      statusChatEditor={statusChatEditor}
      showModalTicketLogs={showModalTicketLogs}
      setShowModalTicketLogs={setShowModalTicketLogs}
      closeTicketLogsModal={closeTicketLogsModal}
      editorState={editorState}
      setEditorState={setEditorState}
      sendMessage={sendMessage}
      files={files}
      setFiles={setFiles}
      hasFileUploader={hasFileUploader}
      setHasFileUploader={setHasFileUploader}
      audioRecordedFiles={audioRecordedFiles}
      setAudioRecordedFiles={setAudioRecordedFiles}
      currentAudioInfo={currentAudioInfo}
      setCurrentAudioInfo={setCurrentAudioInfo}
      isSearched={isSearched}
      showModalListTasks={showModalListTasks}
      showModalDeleteTicket={showModalDeleteTicket}
      showModalReopenTicket={showModalReopenTicket}
      showModalRecoveryDeletedTicket={showModalRecoveryDeletedTicket}
      showModalSendTicketCopy={showModalSendTicketCopy}
      showMenuAction={showMenuAction}
      setShowMenuAction={setShowMenuAction}
      openModalListTasks={openModalListTasks}
      openModalDeleteTicket={openModalDeleteTicket}
      openModalReopenTicket={openModalReopenTicket}
      openModalRecoveryDeletedTicket={openModalRecoveryDeletedTicket}
      openModalSendTicketCopy={openModalSendTicketCopy}
      closeModalListTasks={closeModalListTasks}
      closeModalDeleteTicket={closeModalDeleteTicket}
      closeModalReopenTicket={closeModalReopenTicket}
      closeModalRecoveryDeletedTicket={closeModalRecoveryDeletedTicket}
      closeModalSendTicketCopy={closeModalSendTicketCopy}
      popoverItemAction={popoverItemAction}
      handleShowTicketMenuAction={handleShowTicketMenuAction}
      hiddenToolbar={hiddenToolbarRef.current}
    />
  );
}

export default ViewTicketController;