import { FC, useEffect, useState, MouseEvent, SetStateAction } from 'react';
import ModalListTasks from './index';
import { FetchTaskInterface, FetchTicketTasksInterface, ModalListTasksControllerInterface, TaskInterface, TicketTasksInterface } from './indexModel';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import emitter from '../../../../../core/shared/emitter';
import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import UserService from '../../../../../services/user-service';
import { setShowAlertFeedback } from '../../../../../store/internal';
import { CustomTableListHeaders, PaginationDetailsType } from '../../../../../components/customTableList/indexModel';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { IconEdit, IconTrashX, IconUserBolt } from '@tabler/icons-react';
import { getIdUser } from '../../../../../store/user';
import { CustomSelectOptionType } from '../../../../../components/customSelect/indexModel';
import { EmployeeInterface, FetchEmployeesInterface, FetchTagsInterface, TagInterface } from '../../../homeTickets2/viewTicket/components/detailsComponent/indexModel';
import { TicketStatus } from '../../../../../core/enums/ticket-status';
import { getIdEmployee } from '../../../../../store/employee';
import i18n from '../../../../../i18next';

const AppRequesterConst = new AppRequesterController();

const DATE_SIZE = '160px';
const DESCRIPTION_SIZE = '230px';
const MAX_DESCRIPTION_SIZE = '230px';
const ASSIGNED_EMPLOYEE_SIZE = '170px';
const STATUS_SIZE = '60px';
const LIMIT_PAGINATION = 25;
const channelIconSize = 20;
const channelIconColor = '#707070';

const ModalListTasksController: FC<ModalListTasksControllerInterface> = (props) => {
  const { t } = useTranslation('ModalListTasks');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loggedUserId = useSelector(getIdUser);
  const loggedEmployeeId = useSelector(getIdEmployee);

  const [listHeaders, setListHeaders] = useState<CustomTableListHeaders[]>([]);
  const [data, setData] = useState<TicketTasksInterface[]>([]);
  const [ticketInfo, setTicketInfo] = useState<{ id: string, referenceId: string }>();
  const [taskInfo, setTaskInfo] = useState<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [paginationDetails, setPaginationDetails] = useState<PaginationDetailsType>();
  const [errorLoading, setErrorLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [scene, setScene] = useState<'list' | 'create' | 'edit' | 'only-edit'>('list');
  const [selectedTaskId, setSelectedTaskId] = useState<string>();
  const [taskData, setTaskData] = useState<TaskInterface>();

  // Agente da tarefa
  const [selectedAgent, setSelectedAgent] = useState<CustomSelectOptionType>(null);
  const [agentsList, setAgentsList] = useState<EmployeeInterface[]>([]);
  const [agentsListPage, setAgentsListPage] = useState(1);
  const [hasMoreAgents, setHasMoreAgents] = useState(false);
  const [isLoadingAgentsSelect, setIsLoadingAgentsSelect] = useState(false);

  // Status da tarefa
  const [selectedStatus, setSelectedStatus] = useState<CustomSelectOptionType>(null);

  // Seguidor do ticket
  const [selectedFollower, setSelectedFollower] = useState<CustomSelectOptionType[]>(null);
  const [followersList, setFollowersList] = useState<EmployeeInterface[]>([]);
  const [followersListPage, setFollowersListPage] = useState(1);
  const [hasMoreFollowers, setHasMoreFollowers] = useState(false);
  const [isLoadingFollowersSelect, setIsLoadingFollowersSelect] = useState(false);

  // Tags do ticket
  const [selectedTag, setSelectedTag] = useState<CustomSelectOptionType[]>(null);
  const [tagsList, setTagsList] = useState<TagInterface[]>([]);
  const [tagsListPage, setTagsListPage] = useState(1);
  const [hasMoreTags, setHasMoreTags] = useState(false);
  const [isLoadingTagsSelect, setIsLoadingTagsSelect] = useState(false);

  // Description
  const [taskDescription, setTaskDescription] = useState<string>('');

  // Description
  const [taskNote, setTaskNote] = useState<string>('');

  // Date
  const [dateLimit, setDateLimit] = useState<Date>();
  const [hourLimit, setHourLimit] = useState<Date>();
  const [notified, setNotified] = useState<boolean>(false);
  const [shouldNotificate, setShouldNotificate] = useState(false);
  const [notificationPeriod, setNotificationPeriod] = useState<number>(1);
  const [notificationUnit, setNotificationUnit] = useState<CustomSelectOptionType>();
  const [notificationDate, setNotificationDate] = useState<Date>();

  const [itemDeleteConfirmation, setItemDeleteConfirmation] = useState<TicketTasksInterface>();

  // Status
  const statusList = [
    { id: TicketStatus.New, value: t('form.status.new') },
    { id: TicketStatus.Open, value: t('form.status.open') },
    { id: TicketStatus.Pending, value: t('form.status.pending') },
    { id: TicketStatus.OnHold, value: t('form.status.on_hold') },
    { id: TicketStatus.Resolved, value: t('form.status.resolved') },
    { id: TicketStatus.Abandoned, value: t('form.status.abandoned') },
  ];

  // Status
  const timeUnitList = [
    { id: 'hours', value: t('form.notification_unit.hours') },
    { id: 'days', value: t('form.notification_unit.days') },
    { id: 'weeks', value: t('form.notification_unit.weeks') },
  ];

  useEffect(() => {
    emitter.on('open-modal-list-tasks', data => {
      setScene('list');
      setTicketInfo({ id: data.ticketId, referenceId: data.ticketReferenceId });
    });

    emitter.on('open-modal-task', data => {
      setScene('only-edit');
      setTaskInfo({ id: data.taskId });
    });

    return () => {
      emitter.off('open-modal-list-tasks');
      emitter.off('open-modal-task');
    }
  }, []);

  useEffect(() => {
    if (ticketInfo?.id) {
      setCurrentPage(1);
      fetchTasks({ limit: LIMIT_PAGINATION, page: 1, ticket_id: ticketInfo.id });
      setItemDeleteConfirmation(null);
    }
  }, [ticketInfo]);

  useEffect(() => {
    if (taskInfo?.id) {
      fetchTask(taskInfo.id);
    }
  }, [taskInfo]);

  useEffect(() => {
    setListHeaders([
      { title: t("list_headers.date_limit"), field: 'date_limit', width: DATE_SIZE },
      { title: t("list_headers.assigned"), field: 'assigned_employee_name', width: ASSIGNED_EMPLOYEE_SIZE, hasTooltip: true },
      { title: t("list_headers.description"), field: 'description', width: DESCRIPTION_SIZE, maxWidth: MAX_DESCRIPTION_SIZE, hasTooltip: true },
      { title: t("list_headers.status"), field: 'status_task_id', width: STATUS_SIZE, alignment: 'center' },
      { title: t("list_headers.actions"), field: 'action', width: 'auto' },
    ]);
  }, []);

  useEffect(() => {
    fetchAll();
  }, []);

  useEffect(() => {
    emitter.emit('clear-all-search-custom-select');
    if (scene !== 'edit') {
      setSelectedTaskId(null);
      resetForm();
    } else {
      fetchAll();
      setItemDeleteConfirmation(null);
    }
  }, [scene]);

  useEffect(() => {
    if (dateLimit && hourLimit) {
      setNotificationDate(getNotificationDate());
    }
  }, [shouldNotificate, notificationPeriod, notificationUnit, dateLimit, hourLimit]);

  const fetchAll = async () => {
    setIsLoading(true);
    await Promise.all([
      fetchAgents(false, 1),
      fetchFollowers(false, 1),
      fetchTags(false, 1),
    ]).then(() => {
      setIsLoading(false);
    });
  }

  const resetForm = () => {
    setSelectedAgent(null);
    setSelectedFollower([]);
    setSelectedTag([]);
    setTaskDescription('');
    setTaskNote('');
    setDateLimit(null);
    setHourLimit(null);
    setShouldNotificate(false);
    setNotificationPeriod(1);
    setNotificationUnit(null);
    setNotificationDate(null);
  }

  const getStatusName = (id: string) => {
    switch (id) {
      case TicketStatus.New:
        return t('form.status.new');
      case TicketStatus.Open:
        return t('form.status.open');
      case TicketStatus.Pending:
        return t('form.status.pending');
      case TicketStatus.OnHold:
        return t('form.status.on_hold');
      case TicketStatus.Resolved:
        return t('form.status.resolved');
      case TicketStatus.Abandoned:
        return t('form.status.abandoned');
      case TicketStatus.OverdueTicketTask:
        return t('form.status.overdue');
    }
  }

  const getPeriodUnitName = (id: string) => {
    switch (id) {
      case 'hours':
        return t('form.notification_unit.hours');
      case 'days':
        return t('form.notification_unit.days');
      case 'weeks':
        return t('form.notification_unit.weeks');
    }
  }

  const loadDataFields = (taskData: TaskInterface) => {
    if (taskData) {
      setSelectedAgent({ id: taskData.assigned_employee_id, value: taskData.assigned_employee_name });
      setSelectedFollower(taskData.followers.map(item => ({ id: item.employee_id, value: item.employee_name })));
      setSelectedTag(taskData.tags.map(item => ({ id: item.tag_id, value: item.name })));
      setTaskDescription(taskData.description);
      setTaskNote(taskData.observation || '');
      setDateLimit(new Date(taskData.date_limit));
      setHourLimit(new Date(taskData.date_limit));
      setShouldNotificate(taskData.should_notificate);
      setNotificationPeriod(taskData.notification_alert_time);
      setNotificationUnit({ id: taskData.notification_alert_type, value: getPeriodUnitName(taskData.notification_alert_type) });
      setNotified(taskData.notified);
      if (taskData.notification_alert_date) {
        setNotificationDate(new Date(taskData.notification_alert_date));
      }

      if (new Date(taskData.date_limit) > new Date()) {
        setSelectedStatus({ id: taskData.status_task_id, value: getStatusName(taskData.status_task_id) });
      } else {
        setSelectedStatus(null);
      }
    }
  }

  const fetchTasks = async (params?: any, isScrollPagination?: boolean) => {
    if (ticketInfo?.id) {
      setErrorLoading(false);

      const headers = UserService.getHeaders();
      const config = { params, headers };

      if (!params) {
        config['params'] = {
          page: 1,
          limit: LIMIT_PAGINATION,
          ticket_id: ticketInfo.id
        }
      }

      AppRequesterConst.Get(
        '/task', config,
        () => {},
        (response: FetchTicketTasksInterface) => {
          if (response.status === 200 && response.data.tasks.length > 0) {

            if (response.data.tasks.length === LIMIT_PAGINATION) {
              setHasMoreData(true);
            } else {
              setHasMoreData(false);
            }

            if (isScrollPagination) {
              const new_array = [...data, ...response.data.tasks];
              setData(new_array);
            } else {
              setData(response.data.tasks || []);
            }

            processPaginationDetails(response.data.pagination);
          } else {
            setData([]);
          }
        },
        () => {
          dispatch(setShowAlertFeedback({ message: t("alert.unexpected_error"), visibility: true, signalIcon: false }));
          setErrorLoading(true);
          setIsLoading(false);
        },
        navigate, dispatch, setIsLoading, {}
      );
    } else {
      setErrorLoading(true);
      setIsLoading(false);
    }
  }

  const getTasksPagination = async (page?: number, type?: 'pagination' | 'infinite') => {
    const params = { limit: LIMIT_PAGINATION, page: currentPage, ticket_id: ticketInfo?.id };

    if (page) {
      params.page = page;
      setCurrentPage(page);
      await fetchTasks(params, false);
    } else {
      if (hasMoreData) {
        params.page += 1;
        setCurrentPage(params.page);
  
        await fetchTasks(params, true);
      }
    }
  }

  const reloadTasks = () => {
    const params = { limit: LIMIT_PAGINATION, page: 1, ticket_id: ticketInfo?.id };
    fetchTasks(params);
  }

  const getListHeaders = (): CustomTableListHeaders[] => {
    return listHeaders;
  }

  const processPaginationDetails = (pagination: PaginationDetailsType) => {
    if (pagination) {
      setPaginationDetails({
        currentPage: pagination.currentPage,
        prevPage: pagination.prevPage,
        nextPage: pagination.nextPage,
        lastPage: pagination.lastPage,
        hasPrev: pagination.prevPage !== null,
        hasNext: pagination.nextPage !== null,
        from: pagination.from + 1,
        to: pagination.to,
        perPage: pagination.perPage,
        total: pagination.total,
        pages: Array.from(Array(pagination.lastPage || 1), (x, i) => i + 1)
      });
    }
  }

  const fetchTask = (task_id: string) => {
    const headers = UserService.getHeaders();

    AppRequesterConst.Get(
      `/task/${task_id}`, { headers },
      () => {},
      (response: FetchTaskInterface) => {
        if (response.status === 200) {
          setTaskData(response.data.task);
          loadDataFields(response.data.task);
        }
      },
      () => {
        dispatch(setShowAlertFeedback({ message: t("alert.unexpected_error"), visibility: true, signalIcon: false }));
      },
      navigate, dispatch, setIsLoading, {}
    );
  }

  const selectItem = (item: TicketTasksInterface, event: MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.stopPropagation();
    
    if (item) {
      setSelectedTaskId(item.id);
      fetchTask(item.id);
      setScene('edit');
    }
  }

  const handlePopoverModalListTasksItemAction = (event: any, data: TicketTasksInterface, type: 'edit-task' | 'delete-task') => {
    event.stopPropagation();
    emitter.emit('close-item-action-popover-modal-list-tasks');
    setTimeout(() => {
      if (type === 'edit-task') {
        selectItem(data, event);
      } else if (type === 'delete-task') {
        showDeleteConfirmation(data, true);
      }
    }, 50);
  }

  const popoverItemAction = (data: any) => {
    return (
      <Popover bsPrefix='popover-custom-action-menu'>
        <Popover.Body>
          <span className='popover-item' onClick={(e) => handlePopoverModalListTasksItemAction(e, data, 'edit-task')}>
            <IconEdit />{t('list_popover_actions.edit_task')}
          </span>
          <span className='popover-item' onClick={(e) => handlePopoverModalListTasksItemAction(e, data, 'delete-task')}>
            <IconTrashX />{t('list_popover_actions.delete_task')}
          </span>
        </Popover.Body>
      </Popover>
    );
  }

  const showDeleteConfirmation = (task: TicketTasksInterface, value: boolean) => {
    if (value) {
      setItemDeleteConfirmation(task);
    } else {
      setItemDeleteConfirmation(null);
    }
  }

  const isValid = () => {
    let valid = true;

    if (!selectedAgent?.id) {
      valid = false;
    }
    if (!dateLimit) {
      valid = false;
    }
    if (!hourLimit) {
      valid = false;
    }
    if (!taskDescription) {
      valid = false;
    }
    if (shouldNotificate && (!notificationPeriod || !notificationUnit)) {
      valid = false;
    }

    return valid && isValidDate() && isValidNotificationDate();
  }

  const isValidDate = () => {
    if (dateLimit && hourLimit) {
      const date = new Date(dateLimit);
      const hour = hourLimit.toLocaleTimeString();
      const formattedHour = hour.slice(0, 5).split(':').map((value: string) => Number(value));

      date.setHours(formattedHour[0], formattedHour[1], 0, 0);
      const valid = date > new Date(Date.now());

      return valid;
    } else {
      return false;
    }
  }

  const isValidNotificationDate = () => {
    if (notificationDate && shouldNotificate) {
      return notificationDate > new Date();
    } else {
      return true;
    }
  }

  const getNotificationDate = () => {
    if (dateLimit && hourLimit) {
      const date = new Date(dateLimit);
      const hour = hourLimit.toLocaleTimeString();
      const formattedHour = hour.slice(0, 5).split(':').map((value: string) => Number(value));

      date.setHours(formattedHour[0], formattedHour[1], 0, 0);

      const notifyDateTemp = new Date(date);
      let hours = notificationPeriod;

      if (!shouldNotificate) {
        hours = 24;
      }

      if (notificationUnit?.id === 'days') {
        hours = notificationPeriod * 24;
      } else if (notificationUnit?.id === 'weeks') {
        hours = notificationPeriod * 24 * 7;
      }

      notifyDateTemp.setHours(notifyDateTemp.getHours() - hours);

      return notifyDateTemp;
    } else {
      return new Date(Date.now());
    }
  }

  const handleCreate = () => {
    if (isValid()) {
      const date = getDateISOString(dateLimit);
      const hour = hourLimit.toLocaleTimeString();
  
      const data = {
        assigned_employee_id: selectedAgent.id,
        date_limit: new Date(`${date} ${hour}`).getTime() / 1000,
        description: taskDescription,
        followers_id: selectedFollower.map(item => item.id),
        requester_employee_id: loggedEmployeeId,
        tags_id: selectedTag.map(item => item.id),
        ticket_id: ticketInfo.id,
        should_notificate: shouldNotificate
      }
  
      if (taskNote) {
        data['observation'] = taskNote;
      }
  
      if (shouldNotificate) {
        data['notification_alert_time'] = notificationPeriod?.toString() || '1';
        data['notification_alert_type'] = notificationUnit.id;
      }

      const headers = UserService.getHeaders();

      AppRequesterConst.Post(
        '/task', data, { headers },
        () => {},
        () => {
          setScene('list');
          dispatch(setShowAlertFeedback({ message: t('alert.success_create'), visibility: true, signalIcon: true }));
          fetchTasks({ limit: LIMIT_PAGINATION, page: 1, ticket_id: ticketInfo.id });
        },
        (error) => {
          dispatch(setShowAlertFeedback({ message: t('alert.unexpected_error'), visibility: true, signalIcon: false }));
        },
        navigate, dispatch, setIsLoading
      );
    } else {
      setIsLoading(false); // hack para exibir a mensagem de erro, para causar re-render
    }
  }

  const handleEdit = () => {
    if (isValid()) {
      const date = getDateISOString(dateLimit);
      const hour = hourLimit.toLocaleTimeString();
  
      const data = {
        id: taskData.id,
        assigned_employee_id: selectedAgent.id,
        status_task_id: selectedStatus.id,
        date_limit: new Date(`${date} ${hour}`).getTime() / 1000,
        description: taskDescription,
        followers_id: selectedFollower.map(item => item.id),
        tags_id: selectedTag.map(item => item.id),
        should_notificate: shouldNotificate,
        observation: taskNote || ''
      }
  
      if (shouldNotificate) {
        data['notification_alert_time'] = notificationPeriod?.toString() || '1';
        data['notification_alert_type'] = notificationUnit.id;
      }

      const headers = UserService.getHeaders();

      AppRequesterConst.Put(
        '/task', data, { headers },
        () => {},
        () => {
          if (scene === 'only-edit') {
            dispatch(setShowAlertFeedback({ message: t('alert.success_edit'), visibility: true, signalIcon: true }));
            props.hide(true);
          } else {
            setScene('list');
            dispatch(setShowAlertFeedback({ message: t('alert.success_edit'), visibility: true, signalIcon: true }));
            fetchTasks({ limit: LIMIT_PAGINATION, page: 1, ticket_id: ticketInfo.id });
          }
        },
        (error) => {
          if (error.response?.data && error.response.data.code_cxpress) {
            dispatch(setShowAlertFeedback({ message: `${error.response.data.message} (${error.response.data.code_cxpress})`, visibility: true, signalIcon: false }));
          } else {
            dispatch(setShowAlertFeedback({ message: t('alert.unexpected_error'), visibility: true, signalIcon: false }));
          }
        },
        navigate, dispatch, setIsLoading
      );
    } else {
      setIsLoading(false); // hack para exibir a mensagem de erro, para causar re-render
    }
  }

  const handleDeleteTask = () => {
    if (itemDeleteConfirmation) {
      const headers = UserService.getHeaders();
  
      AppRequesterConst.Delete(
        `/task/${itemDeleteConfirmation.id}`, { headers },
        () => {},
        () => {
          setScene('list');
          setItemDeleteConfirmation(null);
          dispatch(setShowAlertFeedback({ message: t('alert.success_remove'), visibility: true, signalIcon: true }));
          fetchTasks({ limit: LIMIT_PAGINATION, page: 1, ticket_id: ticketInfo.id });
        },
        (error) => {
          if (error.response?.data && error.response.data.code_cxpress) {
            dispatch(setShowAlertFeedback({ message: `${error.response.data.message} (${error.response.data.code_cxpress})`, visibility: true, signalIcon: false }));
          } else {
            dispatch(setShowAlertFeedback({ message: t('alert.unexpected_error'), visibility: true, signalIcon: false }));
          }
        },
        navigate, dispatch, setIsLoading
      );
    } else {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    setScene('list');
  }

  // ===================================
  // Request Agents

  const fetchAgents = async (isInfiniteScroll: boolean, newPage: number, termSearch?: string) => {
    const headers = UserService.getHeaders();
    const params = { limit: LIMIT_PAGINATION, page: newPage || agentsListPage };

    if (termSearch) {
      params['search'] = termSearch;
    }

    const config = { headers, params };

    setAgentsListPage(newPage);

    await AppRequesterConst.Get('/agent', config, 
      (_response: any) => {},
      (response: FetchEmployeesInterface) => {
        if (response.status === 200 && response.data.employees.length > 0) {
          if (response.data.employees.length === LIMIT_PAGINATION) {
            setHasMoreAgents(true);
          } else {
            setHasMoreAgents(false);
          }

          if (isInfiniteScroll) {
            const new_array = [...agentsList, ...response.data.employees];
            setAgentsList(new_array);
          } else {
            setAgentsList(response.data.employees || []);
          }
        } else {
          if (!isInfiniteScroll) {
            setAgentsList([]);
          }
        }
      },
      (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {

      }, navigate, dispatch, setIsLoadingAgentsSelect, {}
    );
  }

  const fetchAgentsPagination = async () => {
    if (hasMoreAgents) {
      const newPage = agentsListPage + 1;
      setAgentsListPage(newPage);

      await fetchAgents(true, newPage);
    }
  }

  const fetchSearchAgents = (term: string) => {
    if (term) {
      fetchAgents(false, 1, term);
    } else {
      fetchAgents(false, 1, '');
    }
  }
  
  // ===================================
  // Request Followers

  const fetchFollowers = async (isInfiniteScroll: boolean, newPage: number, termSearch?: string) => {
    const headers = UserService.getHeaders();
    const params = { limit: LIMIT_PAGINATION, page: newPage || followersListPage };

    if (termSearch) {
      params['search'] = termSearch;
    }

    const config = { headers, params };

    setFollowersListPage(newPage);

    await AppRequesterConst.Get('/agent', config, 
      (_response: any) => {},
      (response: FetchEmployeesInterface) => {
        if (response.status === 200 && response.data.employees.length > 0) {
          if (response.data.employees.length === LIMIT_PAGINATION) {
            setHasMoreFollowers(true);
          } else {
            setHasMoreFollowers(false);
          }

          if (isInfiniteScroll) {
            const new_array = [...followersList, ...response.data.employees];
            setFollowersList(new_array);
          } else {
            setFollowersList(response.data.employees || []);
          }
        } else {
          if (!isInfiniteScroll) {
            setFollowersList([]);
          }
        }
      },
      (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {

      }, navigate, dispatch, setIsLoadingFollowersSelect, {}
    );
  }

  const fetchFollowersPagination = async () => {
    if (hasMoreFollowers) {
      const newPage = followersListPage + 1;
      setFollowersListPage(newPage);

      await fetchFollowers(true, newPage);
    }
  }

  const fetchSearchFollowers = (term: string) => {
    if (term) {
      fetchFollowers(false, 1, term);
    } else {
      fetchFollowers(false, 1, '');
    }
  }

  // ===================================
  // Request Tags

  const fetchTags = async (isInfiniteScroll: boolean, newPage: number, termSearch?: string) => {
    const headers = UserService.getHeaders();
    const params = { limit: LIMIT_PAGINATION, page: newPage || tagsListPage };

    if (termSearch) {
      params['search'] = termSearch;
    }

    const config = { headers, params };

    setTagsListPage(newPage);

    await AppRequesterConst.Get('/tag', config, 
      (_response: any) => {},
      (response: FetchTagsInterface) => {
        if (response.status === 200 && response.data.tags.length > 0) {
          if (response.data.tags.length === LIMIT_PAGINATION) {
            setHasMoreTags(true);
          } else {
            setHasMoreTags(false);
          }

          if (isInfiniteScroll) {
            const new_array = [...tagsList, ...response.data.tags];
            setTagsList(new_array);
          } else {
            setTagsList(response.data.tags || []);
          }
        } else {
          if (!isInfiniteScroll) {
            setTagsList([]);
          }
        }
      },
      (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {

      }, navigate, dispatch, setIsLoadingTagsSelect, {}
    );
  }

  const fetchTagsPagination = async () => {
    if (hasMoreTags) {
      const newPage = tagsListPage + 1;
      setTagsListPage(newPage);

      await fetchTags(true, newPage);
    }
  }

  const fetchSearchTags = (term: string) => {
    if (term) {
      fetchTags(false, 1, term);
    } else {
      fetchTags(false, 1, '');
    }
  }

  // ===================================

  const getAgentsSelect = () => {
    return {
      agentsList: formatDataSelect(agentsList, 'agent'),
      selectedAgent,
      setSelectedAgent,
      isLoadingAgentsSelect,
      fetchAgentsPagination,
      fetchSearchAgents,
      hasMoreAgents,
    };
  }

  const getStatusSelect = () => {
    return {
      statusList,
      selectedStatus,
      setSelectedStatus,
    }
  }

  const getFollowersSelect = () => {
    return {
      followersList: formatDataSelect(followersList, 'follower'),
      selectedFollower,
      setSelectedFollower,
      isLoadingFollowersSelect,
      fetchFollowersPagination,
      fetchSearchFollowers,
      hasMoreFollowers,
    };
  }

  const getTagsSelect = () => {
    return {
      tagsList: formatDataSelect(tagsList, 'tag'),
      selectedTag,
      setSelectedTag,
      isLoadingTagsSelect,
      fetchTagsPagination,
      fetchSearchTags,
      hasMoreTags,
    };
  }

  const getDateInput = () => {
    return {
      dateLimit,
      onSelectDate,
      hourLimit,
      onSelectHour
    }
  }

  const getUnitPeriodSelect = () => {
    return {
      timeUnitList,
      notificationUnit,
      setNotificationUnit,
    }
  }

  const formatDataSelect = (data: any[], type: 'agent' | 'sector' | 'follower' | 'tag'): { id: string, value: string}[] => {
    const formatted = [];

    data.forEach(item => {
      if (type === 'agent' || type === 'follower') {

        const dataOption = { id: item.id, value: item.name };

        if (item.user_id === loggedUserId) {
          dataOption['icon'] = (
            <OverlayTrigger placement='right' overlay={<Tooltip>{t('form.icon_you')}</Tooltip>}>
              <IconUserBolt stroke={1.5} size={channelIconSize} color={channelIconColor} />
            </OverlayTrigger>
          );
          
          dataOption['iconPosition'] = 'end';
        }

        formatted.push(dataOption);
      } else if (type === 'sector' || type === 'tag') {
        formatted.push({ id: item.id, value: item.name });
      }
    });

    return formatted;
  }

  const onSelectDate = (date: Date) => {
    setDateLimit(date);
  }

  const onSelectHour = (date: Date) => {
    setHourLimit(date);
  }

  const formatDate = (date: Date, type?: string): string => {
    const language = i18n.language;
    if (type === 'short') {
      return `${new Date(date).toLocaleDateString(language)}`;
    } else {
      return `${new Date(date).toLocaleDateString(language)} - ${new Date(date).toLocaleTimeString(language)}`;
    }
  }

  const getDateISOString = (date: Date) => {
    if (date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // `getMonth()` retorna de 0 a 11, então adicionamos 1
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    }
  }

  const showLoading = () => {
    if (scene === 'create') {
      return isLoading;
    } else if (scene === 'edit') {
      return isLoading || !taskData;
    } else if (scene === 'only-edit') {
      return isLoading || !taskData;
    } else {
      return !data;
    }
  }

  return (
    <ModalListTasks 
      t={t}
      show={props.show}
      hide={props.hide}
      ticketInfo={ticketInfo}
      taskInfo={taskInfo}
      listHeaders={getListHeaders}
      data={data}
      reloadTasks={reloadTasks}
      isLoading={isLoading}
      isLoadingSave={isLoadingSave}
      showLoading={showLoading}
      paginationDetails={paginationDetails}
      getTasksPagination={getTasksPagination}
      errorLoading={false}
      popoverItemAction={popoverItemAction}
      scene={scene}
      setScene={setScene}
      selectItem={selectItem}
      handleCreate={handleCreate}
      handleEdit={handleEdit}
      handleDeleteTask={handleDeleteTask}
      handleCancel={handleCancel}
      isValid={isValid}
      isValidDate={isValidDate}
      isValidNotificationDate={isValidNotificationDate}
      notificationDate={notificationDate}
      taskData={taskData}
      getAgentsSelect={getAgentsSelect}
      getStatusSelect={getStatusSelect}
      getFollowersSelect={getFollowersSelect}
      getTagsSelect={getTagsSelect} 
      taskDescription={taskDescription} setTaskDescription={setTaskDescription} 
      taskNote={taskNote} setTaskNote={setTaskNote}
      getDateInput={getDateInput}
      notified={notified}
      shouldNotificate={shouldNotificate}
      setShouldNotificate={setShouldNotificate}
      notificationPeriod={notificationPeriod}
      setNotificationPeriod={setNotificationPeriod}
      getUnitPeriodSelect={getUnitPeriodSelect}
      formatDate={formatDate}
      showDeleteConfirmation={showDeleteConfirmation}
      itemDeleteConfirmation={itemDeleteConfirmation}
      getStatusName={getStatusName}
    />
  );
};

export default ModalListTasksController;
